import { ApiResponse } from 'apisauce';
import BaseApi from './BaseApi';
import { ApiConfig, DEFAULT_API_CONFIG } from './config';

export class ApiClient extends BaseApi {
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config);
  }

  /**
   * LOGIN
   *
   * @param params
   */
  async login(params: Api.Req.Login): Promise<Api.Response<Api.Res.Login>> {
    const response: ApiResponse<any> = await this.api.post(
      '/auth/login',
      params,
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;
    this.setToken(data.token);

    return { kind: 'ok', data };
  }

  /**
   * CHANGE PASSWORD
   *
   * @param params
   */
  async changePassword(
    params: Api.Req.ChangePassword,
  ): Promise<Api.Response<Api.Res.ChangePassword>> {
    const response: ApiResponse<any> = await this.api.post(
      '/auth/change-password',
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    return { kind: 'ok', data: response.data };
  }

  /**
   * LOGOUT
   *
   * @param params
   */
  async logout(_: Api.Req.Logout): Promise<Api.Response<Api.Res.Logout>> {
    await this.api.post('/auth/logout', {}, this.auth());

    this.setToken(undefined);

    return { kind: 'ok', data: {} };
  }

  /**
   * GET AUTHENTICATED USER
   *
   * @param params
   */
  async getMe(params: Api.Req.GetMe): Promise<Api.Response<Api.Res.GetMe>> {
    const response: ApiResponse<any> = await this.api.get(
      '/users/me',
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * UPDATE USER INFO
   *
   * @param params
   */
  async updateMe(
    params: Api.Req.UpdateMe,
  ): Promise<Api.Response<Api.Res.GetMe>> {
    const response: ApiResponse<any> = await this.api.patch(
      '/users/me',
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * RESET PASSWORD REQUEST
   *
   * @param params
   */
  async passwordResetRequest(params: {
    username: string;
  }): Promise<Api.Response<any>> {
    const response: ApiResponse<any> = await this.api.post(
      `/auth/resetrequest`,
      params,
      this.auth(),
    );
    if (!response.ok) return this.handleError(response);

    return { kind: 'ok', data: response.data.data };
  }

  /**
   * RESET PASSWORD
   *
   * @param params
   */
  async passwordReset(
    params: Api.Req.PasswordReset,
  ): Promise<Api.Response<any>> {
    const response: ApiResponse<any> = await this.api.post(
      `/auth/resetpassword`,
      params,
      this.auth(),
    );
    if (!response.ok) return this.handleError(response);

    return { kind: 'ok', data: response.data.data };
  }

  /**
   * GET LANGUAGES
   *
   * @param params
   */
  async getLanguages(): Promise<Api.Response<Api.Res.GetLanguages>> {
    const response: ApiResponse<any> = await this.api.get(
      '/language',
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET NAVIGATION
   *
   * @param params
   */
  async getNavigation(
    params: Api.Req.GetNavigation,
  ): Promise<Api.Response<Api.Res.GetNavigation>> {
    const response: ApiResponse<any> = await this.api.get(
      '/content/navigation',
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET CONTENT PAGES
   *
   * @param params
   */
  async getContentPages(
    params: Api.Req.GetContentPages,
  ): Promise<Api.Response<Api.Res.GetContentPages>> {
    const response: ApiResponse<any> = await this.api.get(
      '/content',
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET CONTENT PAGE
   *
   * @param params
   */
  async getContentPage({
    slug,
    ...params
  }: Api.Req.GetContentPage): Promise<Api.Response<Api.Res.GetContentPage>> {
    const response: ApiResponse<any> = await this.api.get(
      `/content/navigation/${slug}`,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET CONTENT PAGE
   *
   * @param params
   */
  async getSharedContent({
    token,
  }: Api.Req.GetSharedContent): Promise<
    Api.Response<Api.Res.GetSharedContent>
  > {
    const response: ApiResponse<any> = await this.api.get(
      `/sharing?token=${token}`,
      {},
      {},
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }

  /**
   * GET PRODUCTS
   *
   * @param params
   */
  async getProducts(
    _: Api.Req.GetProducts,
  ): Promise<Api.Response<Api.Res.GetProducts>> {
    const response: ApiResponse<any> = await this.api.get(
      '/products',
      {},
      this.auth(),
    );
    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * GET PRODUCT
   *
   * @param params
   */
  async getProduct({
    slug,
  }: Api.Req.GetProduct): Promise<Api.Response<Api.Res.GetProduct>> {
    const response: ApiResponse<any> = await this.api.get(
      `/products/slug/${slug}`,
      {},
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * SEND FEEDBACK
   *
   * @param params
   */
  async sendFeedback(
    params: Api.Req.SendFeedback,
  ): Promise<Api.Response<Api.Res.SendFeedback>> {
    const response: ApiResponse<any> = await this.api.post(
      `/feedback`,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * SEND EVENT
   *
   */
  async sendEvent(
    event: Api.Req.SendEvent,
  ): Promise<Api.Response<Api.Res.Event>> {
    const response: ApiResponse<any> = await this.api.post(
      '/events',
      event,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);

    const { data } = response;

    return { kind: 'ok', data };
  }

  /**
   * Get all areas
   *
   * @param param
   */
  async getAreas(
    params: Api.Req.GetAreas,
  ): Promise<Api.Response<Api.Res.GetAreas>> {
    const url = 'area';
    const response: ApiResponse<any> = await this.api.get(
      url,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }

  /**
   * Get one person
   *
   * @param param
   */
  async getPerson({
    id,
    ...params
  }: Api.Req.GetPerson): Promise<Api.Response<Api.Res.GetPerson>> {
    const url = `person/${id}`;
    const response: ApiResponse<any> = await this.api.get(
      url,
      params,
      this.auth(),
    );

    if (!response.ok) return this.handleError(response);
    const { data } = response;
    return { kind: 'ok', data };
  }
}

export const api = new ApiClient();

export default api;
