import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const AreaModel = types.model({
  id: types.identifierNumber,
  code: types.string,
  name: types.string,
  domain: types.string,
});

export interface IAreaModel extends Instance<typeof AreaModel> {}
export interface Area extends SnapshotOut<typeof AreaModel> {}
export interface AreaIn extends SnapshotIn<typeof AreaModel> {}
