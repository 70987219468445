import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import DataBlock from '../../components/DataBlock';
import ContentBlock from '../../components/Layout/ContentBlock';
import Layout from '../../components/Layout/Layout';
import Spinner from '../../components/Spinner';
import { ContentPage } from '../../stores/models';
import { useQuery } from '../../utils/hooks';
import { api } from '../../services/api';
import { useParams } from 'react-router-dom';

type FetchingState = 'NOT_FETCHED' | 'FETCHING' | 'FETCHED' | 'ERROR';

export const SharedContentPageScreen: React.FC = observer(() => {
  const { t, i18n } = useTranslation();
  const query = useQuery();
  const { lang } = useParams<{ lang: string }>();
  const tokenRef = useRef<string>();

  const [page, setPage] = useState<ContentPage>();
  const [pageState, setPageState] = useState<FetchingState>('NOT_FETCHED');
  const [errorMsgKey, setErrorMsgKey] = useState<string>();

  const isFetching = pageState === 'FETCHING';

  const token = query.get('token');

  useEffect(() => {
    if (lang !== i18n.language) i18n.changeLanguage(lang);
  }, [i18n, i18n.language, lang]);

  // Fetch page from API
  useEffect(() => {
    const fetchContent = async (token: string) => {
      setPageState('FETCHING');
      const response = await api.getSharedContent({ token });
      if (response.kind === 'ok') {
        setPage(response.data);
        setPageState('FETCHED');
      } else {
        setPageState('ERROR');
        setErrorMsgKey(response.data?.message);
      }
    };

    if (token && tokenRef.current !== token) {
      tokenRef.current = token;
      fetchContent(token);
    }
  }, [token]);

  return (
    <Layout bgImageUrl={page?.backgroundImage?.url} showLanguageSelect={false}>
      <ContentBlock roundedWrapper pullDown>
        <Typography variant="h1" align="center">
          {pageState === 'ERROR' && errorMsgKey
            ? t(errorMsgKey)
            : page?.title ?? ''}
        </Typography>
      </ContentBlock>

      {!page && isFetching && <Spinner verticalPadding="8em" />}

      {page?.data?.map(block => (
        <DataBlock
          key={`${block.type}-${block.id}-${block.__index}`}
          data={block}
        />
      ))}
    </Layout>
  );
});

export default SharedContentPageScreen;
