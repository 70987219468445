import React from 'react';
import { Typography } from '@material-ui/core';
import { Block } from '../../stores/models';
import ContentBlock from '../Layout/ContentBlock';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 2em;

  @media ${p => p.theme.breakpoints.mobile} {
    padding: 0;
  }
`;

interface Props {
  data: Block;
}

const TextBlock: React.FC<Props> = ({ data }) => {
  return (
    <ContentBlock>
      <Container>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: data.body }}
        />
      </Container>
    </ContentBlock>
  );
};

export default TextBlock;
