import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentBlock from '../../components/Layout/ContentBlock';
import Layout from '../../components/Layout/Layout';
import ChangePasswordForm from './ChangePasswordForm';

const UserSettings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <ContentBlock pullDown roundedWrapper="allCorners">
        <Typography variant="h1" align="center">
          {t('userSettings.title')}
        </Typography>

        <div>
          <ChangePasswordForm />
        </div>
      </ContentBlock>
    </Layout>
  );
};

export default UserSettings;
