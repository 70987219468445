import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Block } from '../../stores/models';
import ContentBlock from '../Layout/ContentBlock';

const Container = styled.div`
  padding: 0 2em;
  display: flex;
  justify-content: center;

  @media ${p => p.theme.breakpoints.mobile} {
    padding: 0;
  }
`;

interface Props {
  data: Block;
}

const VideoBlock: React.FC<Props> = ({ data }) => {
  if (!data.videoSrc?.length) return null;

  return (
    <ContentBlock>
      <Container>
        <ReactPlayer url={data.videoSrc} controls />
      </Container>
    </ContentBlock>
  );
};

export default VideoBlock;
