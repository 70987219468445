import ProductIcon from '@material-ui/icons/Star';
import { LoginScreen } from '../screens/Login/LoginScreen';
import { PasswordResetRequestScreen } from '../screens/Login/PasswordResetRequestScreen';
import { PasswordResetScreen } from '../screens/Login/PasswordResetScreen';
import ProductScreen from '../screens/Products/ProductScreen';
import ProductsScreen from '../screens/Products/ProductsScreen';
import ContentPageScreen from '../screens/ContentPage/ContentPageScreen';
import { IRoute } from '../types';
import UserSettings from '../screens/User/UserSettings';
import SharedContentPageScreen from '../screens/ContentPage/SharedContentPageScreen';

const login: IRoute[] = [
  {
    name: 'login',
    href: '/login',
    component: LoginScreen,
    nonAuth: true,
  },
  {
    name: 'passwordResetRequest',
    href: '/resetpassword',
    component: PasswordResetRequestScreen,
    nonAuth: true,
  },
  {
    name: 'passwordReset',
    href: '/resetpassword/:id/:token',
    component: PasswordResetScreen,
    nonAuth: true,
  },
];

const products: IRoute[] = [
  {
    name: 'products',
    href: '/products',
    component: ProductsScreen,
    iconComponent: ProductIcon,
    menuItem: true,
  },
  {
    name: 'products',
    href: '/products/:slug',
    component: ProductScreen,
  },
];

const navigation: IRoute[] = [
  {
    name: 'navigation',
    href: '/:navigationpath',
    component: ContentPageScreen,
    menuItem: true,
    nonAuth: true,
  },
  {
    name: 'navigation',
    href: '/:navigationpath/:tab',
    component: ContentPageScreen,
    nonAuth: true,
  },
];

const user: IRoute[] = [
  {
    name: 'userSettings',
    href: '/user/settings',
    component: UserSettings,
  },
];

const sharing: IRoute[] = [
  {
    name: 'shared-content',
    // E.g. http://localhost:3000/sharing/content/1?token=3e5d7983-1ce4-4b63-a00a-186c33cf83f7
    href: '/:lang/sharing/content/:id',
    component: SharedContentPageScreen,
    nonAuth: true,
  },
];

export const ROUTES: IRoute[] = [
  ...login,
  ...products,
  ...user,
  ...navigation,
  ...sharing,
];

export const pathForRoute = (routeName: string) => {
  return ROUTES.find(({ name }) => name === routeName)?.href;
};
