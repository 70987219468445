import { Badge, BadgeProps, Box } from '@material-ui/core';
import styled from 'styled-components';

export const MenuContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 1em;

  @media ${p => p.theme.breakpoints.tablet} {
    margin-bottom: 0;
  }
`;

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 8,
    backgroundColor: theme.colors.primary,
    border: `1px solid ${theme.colors.white}`,
    color: 'white',
    padding: '0 5px',
    fontWeight: 'bold',
    fontSize: '0.75rem',
  },
}));
