import { useLocation } from 'react-router-dom';

/**
 * Access url's query params
 */
export const useQuery = () => new URLSearchParams(useLocation().search);

/**
 * Returns query parameter values for given keys
 *
 * For example:
 * const { year, week } = useQueryParams('year', 'week');
 * when url is .../something?year=2021&week=1
 */
export const useQueryParams = (...params: string[]) => {
  const query = useQuery();

  const values: { [key: string]: string | null } = {};

  params.forEach(param => (values[param] = query.get(param)));

  return values;
};
