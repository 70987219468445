import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Wrapper, WrapperSize } from './Wrapper';

type BackgroundType = 'negative' | 'grey' | 'success' | 'white' | 'transparent';

const Container = styled.div<{
  background?: BackgroundType;
  pullDown?: boolean;
}>`
  margin-top: ${p => (p.pullDown ? 78 : 0)}px;

  @media ${p => p.theme.breakpoints.mobile} {
    margin-top: ${p => (p.pullDown ? 72 : 0)}px;
  }

  h1,
  h2 {
    margin: 1em 0;
  }

  ${p => {
    switch (p.background) {
      case 'negative':
        return `
          background-color: ${p.theme.colors.primary};
          h2, h3 {
            color: ${p.theme.colors.white};
          }
        `;
      case 'grey':
        return `
          background-color: ${p.theme.colors.greyLighter};
        `;
      case 'success':
        return `
          background-color: ${p.theme.colors.toastGreen};
        `;
      default:
        return '';
    }
  }};

  @media print {
    background-color: transparent;
    color: ${p => p.theme.colors.black};
    break-inside: avoid;
    page-break-inside: avoid;
  }
`;

interface Props {
  title?: string;
  background?: BackgroundType;
  transparentWrapper?: boolean;
  roundedWrapper?: boolean | 'allCorners';
  wrapperSize?: WrapperSize;
  noMargin?: boolean;
  pullDown?: boolean;
}

const ContentBlock: React.FC<Props> = ({
  title,
  background = 'transparent',
  transparentWrapper,
  roundedWrapper,
  wrapperSize = 'default',
  noMargin,
  pullDown,
  children,
}) => {
  return (
    <Container background={background} pullDown={pullDown}>
      <Wrapper
        noMargin={noMargin}
        transparentBg={transparentWrapper || background !== 'transparent'}
        rounded={roundedWrapper}
        wrapperSize={wrapperSize}
      >
        {title && (
          <Typography variant="h3" align="center">
            {title}
          </Typography>
        )}

        {children}
      </Wrapper>
    </Container>
  );
};

export default ContentBlock;
