import React from 'react';
import { Block } from '../../stores/models';
import { SimpleChart } from '../Charts';
import ContentBlock from '../Layout/ContentBlock';

interface Props {
  data: Block;
}

const Chart: React.FC<Props> = ({ data }) => {
  const getChartType = () => {
    switch (data.chartType) {
      case 'linechart':
        return 'lines';
      case 'barchart':
        return 'bars';
      default:
        return undefined;
    }
  };

  // Try to access the data with additional currencies, if it doesn't exist, use the original data
  const chartData = data.csvDataWithCurrencies ?? data.csvData;

  return (
    <ContentBlock title={data.title} background="negative" wrapperSize="small">
      <SimpleChart
        title={data.title}
        data={chartData?.data ?? []}
        dataGroups={chartData?.groups ?? []}
        type={getChartType()}
        leftAxisUnit={data.yUnit}
      />
    </ContentBlock>
  );
};

export default Chart;
