import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { useStores } from '../../stores';
import Layout from '../../components/Layout/Layout';
import { CellRenderers } from '../../components/MaterialTable/CellRenderers';
import Table from '../../components/MaterialTable/MaterialTable';
import Tabs from '../../components/Tabs';
import { useQuery, useQueryParams } from '../../utils/hooks';
import ContentBlock from '../../components/Layout/ContentBlock';
import Spinner from '../../components/Spinner';
import { Product } from '../../stores/models';
import Tooltip from '../../components/Tooltip';

interface SortByProps {
  sortBy: {
    id: string;
    desc: boolean;
  }[];
}

const ProductsContainer = styled(Box)`
  margin-bottom: 2em;
  max-width: 90vw;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
`;

export const isEmpty = (value: any) => value === undefined || value === null;
export const compareUndefinedValues = (a: any, b: any) => {
  if (isEmpty(a) && isEmpty(b)) return 0;
  if (isEmpty(a)) return 1;
  if (isEmpty(b)) return -1;
  return 0;
};

export const compareStringValues = (a: any, b: any) => {
  return String(a).localeCompare(String(b));
};

export const compareNumericValues = (a: any, b: any) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

export const ProductsScreen: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const [orderBy, setOrderBy] = useState<string>();
  const [order, setOrder] = useState<string>('desc');

  const {
    productStore: { state, tabs, getProducts, getCategoryProducts },
  } = useStores();

  const isFetching = state === 'FETCHING';

  // Get selected tab from query parameters
  const { tab } = useQueryParams('tab');
  const materialsTab = tabs.find(({ slug }) => slug === '1')?.slug;
  const firstTab = tabs[0]?.slug;
  const defaultTab = materialsTab ?? firstTab ?? '';
  const slug = tab ?? defaultTab;

  useEffect(() => {
    const fetchProducts = async () => {
      await getProducts();
    };

    fetchProducts();
  }, [getProducts]);

  const handleTabChange = (tab: string) => {
    // Update new tab to url query parameters
    query.set('tab', tab);
    history.push({ search: query.toString() });
  };

  const getData = (categorySlug?: string | number) => {
    return getCategoryProducts(categorySlug);
  };

  const handleTableSortChange = ({ sortBy }: SortByProps) => {
    if (sortBy?.length) {
      const sortColumn = sortBy[0];
      const { id, desc } = sortColumn;
      setOrderBy(id);
      setOrder(desc ? 'desc' : 'asc');
    }
  };

  const sortData = (a: Product, b: Product) => {
    if (orderBy?.length) {
      const aValue = a[orderBy as keyof Product];
      const bValue = b[orderBy as keyof Product];

      // Empty values always at the end
      if (isEmpty(aValue) || isEmpty(bValue)) {
        return compareUndefinedValues(aValue, bValue);
      }

      let sortValue = 0;
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        sortValue = compareNumericValues(aValue, bValue);
      } else {
        sortValue = compareStringValues(aValue, bValue);
      }

      return order === 'desc' ? sortValue : sortValue * -1;
    }
    return 0;
  };

  const data = getData(slug);

  const getTooltip = (key: string) => {
    const content = t(`products.${key}`);
    const tooltip = t(`products.${key}-tooltip`);
    return <Tooltip title={tooltip}>{content}</Tooltip>;
  };

  const columns = [
    {
      accessor: 'name',
      Header: t('products.name'),
      customStyle: { padding: '16px' },
      Cell: CellRenderers.link(row => `/products/${row.slug ?? row.id}`),
    },
    {
      accessor: 'priceExpectation',
      Header: getTooltip('priceExpectation'),
      customStyle: { padding: '16px' },
      Cell: CellRenderers.priceExpectation(
        row => `/products/${row.slug ?? row.id}`,
      ),
    },
    {
      accessor: 'forecast',
      Header: getTooltip('forecast'),
      customStyle: { padding: '16px' },
      Cell: CellRenderers.forecast(row => `/products/${row.slug ?? row.id}`),
    },
    {
      accessor: 'index',
      // Use different label/tooltip for "RAW MATERIALS" category!
      // It is assumed that raw material slug (=ID) is always '2' from now on until forever.
      // If not, obviously, this won't work.
      // (Probably these personalized texts should be managed in admin, but this is how it is for now)
      Header:
        slug === '2' ? getTooltip('rawMaterial-index') : getTooltip('index'),
      customStyle: { padding: '16px' },
      Cell: CellRenderers.link(row => `/products/${row.slug ?? row.id}`),
    },
    {
      accessor: 'dataUpdatedDateTime',
      Header: getTooltip('updated'),
      customStyle: { padding: '16px' },
      Cell: CellRenderers.dateLink(row => `/products/${row.slug ?? row.id}`),
    },
    {
      accessor: '',
      Header: ' ',
      customStyle: { padding: '16px' },
      Cell: CellRenderers.chevronRight(
        row => `/products/${row.slug ?? row.id}`,
      ),
    },
  ];

  return (
    <Layout bgImageUrl="/office_windows.jpg">
      {!!tabs.length && (
        <ContentBlock noMargin transparentWrapper>
          <Tabs tabs={tabs} onTabChange={handleTabChange} selectedTab={slug} />
        </ContentBlock>
      )}

      <ContentBlock roundedWrapper="allCorners" pullDown={!tabs.length}>
        <ProductsContainer>
          <Table
            data={data.sort(sortData)}
            columns={columns}
            paginationEnabled={false}
            onSortingChange={handleTableSortChange}
          />
        </ProductsContainer>
      </ContentBlock>

      {!data?.length && isFetching && <Spinner verticalPadding="8em" />}
    </Layout>
  );
});

export default ProductsScreen;
