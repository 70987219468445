import {
  Instance,
  types,
  SnapshotOut,
  SnapshotIn,
  IAnyModelType,
} from 'mobx-state-tree';
import { MediaModel } from './MediaModel';

export const ChartDataPointGroupModel = types.map(
  types.union(types.string, types.number, types.null),
);

export const ChartDataPointModel = types.map(
  types.union(
    types.string,
    types.number,
    types.boolean,
    types.null,
    ChartDataPointGroupModel,
  ),
);

export const ChartGroupModel = types.map(
  types.union(types.array(types.string), types.string),
);

export const ChartCSVDataModel = types.model({
  data: types.array(ChartDataPointModel),
  groups: types.array(ChartGroupModel),
});
export interface ChartCSVData extends SnapshotOut<typeof ChartCSVDataModel> {}

export const DataTableCell = types.maybeNull(
  types.maybe(types.union(types.string, types.number)),
);
export const DataTableCSVDataModel = types.array(types.array(DataTableCell));
export interface DataTableCSVData
  extends SnapshotOut<typeof DataTableCSVDataModel> {}

export const PersonFormPositions = [
  'hidden' as const,
  'top' as const,
  'bottom' as const,
];

// TODO: Remove any somehow
export const BlockModel: any = types.model({
  __index: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  items: types.optional(
    types.array(types.late((): IAnyModelType => BlockModel)),
    [],
  ),
  csvData: types.maybeNull(
    types.union(ChartCSVDataModel, DataTableCSVDataModel),
  ),
  csvDataType: types.maybeNull(types.string),
  csvDataWithCurrencies: types.maybeNull(
    types.union(ChartCSVDataModel, DataTableCSVDataModel),
  ),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  href: types.maybeNull(types.string),
  publishedDateTime: types.maybeNull(types.string),
  filename: types.maybeNull(types.string),
  chartType: types.maybeNull(types.string),
  showGauge: types.maybeNull(types.boolean),
  gaugeValue: types.maybeNull(types.number),
  imageSrc: types.maybeNull(MediaModel),
  videoSrc: types.maybeNull(types.string),
  altText: types.maybeNull(types.string),
  yUnit: types.maybeNull(types.string),
  scaleMin: types.maybeNull(types.number),
  scaleMax: types.maybeNull(types.number),
  questionType: types.maybeNull(types.string),
  personFormPosition: types.maybeNull(
    types.enumeration('PersonFormPosition', PersonFormPositions),
  ),
  isInputVisible: types.maybeNull(types.boolean),
  sliderInputDecoration: types.maybeNull(types.string),
  sliderStep: types.maybeNull(types.number),
  scaleMinLabel: types.maybeNull(types.string),
  scaleMaxLabel: types.maybeNull(types.string),
});

export interface IBlockModel extends Instance<typeof BlockModel> {}
export interface Block extends SnapshotOut<typeof BlockModel> {}
export interface BlockIn extends SnapshotIn<typeof BlockModel> {}
