import {
  Instance,
  types,
  flow,
  cast,
  getSnapshot,
  getParent,
} from 'mobx-state-tree';
import { ERROR } from '../constants/constants';
import { translate } from '../i18n/translate';
import { api } from '../services/api/ApiClient';
import { ContentPage, ContentPageModel } from './models/ContentPageModel';

const States = ['IDLE' as const, 'FETCHING' as const, 'ERROR' as const];

export const ContentPageStore = types
  .model({
    state: types.enumeration('State', States),
    pages: types.array(ContentPageModel),
  })

  .views(self => ({
    // Get one page by slug (works also if given ID instead)
    getPage(slug: string | number) {
      const pageId = Number(slug);
      const page = self.pages.find(page =>
        pageId ? page.id === pageId : page.slug === slug,
      );
      return page ? getSnapshot(page) : undefined;
    },
  }))
  .actions(self => {
    const fetchPage = flow(function* (params: Api.Req.GetContentPage) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetContentPage> =
        yield api.getContentPage(params);

      const updatePages = (page: ContentPage) => {
        // Remove corresponding page (if exists) & add new/updated page to the store
        const oldPages = self.pages.filter(({ id }) => id !== page.id);
        return [...oldPages, page];
      };

      if (response.kind === 'ok' && response.data) {
        const page = response.data;
        const pages = updatePages(page);

        self.pages = cast(pages);
        self.state = 'IDLE';
      } else {
        notificationStore.setError(translate(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    return {
      fetchPage,
    };
  });

export interface IContentPageStore extends Instance<typeof ContentPageStore> {}

export default ContentPageStore;
