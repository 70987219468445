import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const MediaModel = types.model({
  id: types.identifierNumber,
  url: types.string,
  name: types.string,
});

export interface IMediaModel extends Instance<typeof MediaModel> {}
export interface Media extends SnapshotOut<typeof MediaModel> {}
export interface MediaIn extends SnapshotIn<typeof MediaModel> {}
