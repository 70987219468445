import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Person } from '../stores/models';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem;
  margin: 1rem 0;
  background-color: ${p => p.theme.colors.greyLighter};
  border: 1px solid ${p => p.theme.colors.grey};
  border-radius: 1rem;

  .person-form {
    &__title-wrapper {
      width: 100%;
    }
    &__input-wrapper {
      width: 32%;
      margin-bottom: 1rem;
    }
  }

  .MuiTypography-root,
  .MuiInputBase-input {
    font-size: 0.9rem;
  }

  .MuiInputBase-input {
    padding: 0.4rem 0.6rem;
    background: ${p => p.theme.colors.white};
  }

  @media ${p => p.theme.breakpoints.mobile} {
    flex-direction: column;
    .person-form {
      &__input-wrapper {
        width: 100%;
      }
    }
  }
`;

interface Props {
  formTitle?: string;
  person?: Person;
  onPersonChange: (person: Person) => void;
}

const PersonForm: React.FC<Props> = ({ formTitle, person, onPersonChange }) => {
  const { t } = useTranslation();

  const [name, setName] = useState(person?.name ?? '');
  const [email, setEmail] = useState(person?.email ?? '');
  const [company, setCompany] = useState(person?.company ?? '');
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (changesMade) {
        onPersonChange({
          id: person?.id ?? null,
          name,
          email,
          company,
        });
        setChangesMade(false);
      }
    }, 500);

    return () => clearTimeout(delay);
  }, [name, email, company, onPersonChange, changesMade, person?.id]);

  const handleInputChange =
    (setValue: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(event.currentTarget.value);
      setChangesMade(true);
    };

  const inputs = [
    {
      label: t('person.name'),
      value: name,
      setter: setName,
      required: true,
    },
    {
      label: t('person.email'),
      value: email,
      setter: setEmail,
      required: true,
      type: 'email',
    },
    {
      label: t('person.company'),
      value: company,
      setter: setCompany,
      required: true,
    },
  ];

  return (
    <Container>
      {formTitle && (
        <div className="person-form__title-wrapper">
          <Typography variant="h1">{formTitle}</Typography>
        </div>
      )}

      {inputs.map(({ label, value, setter, required, type }, i) => (
        <div key={`${label}-${i}`} className="person-form__input-wrapper">
          <FormControl fullWidth>
            <Typography>{`${label}${required ? ' *' : ''}`}</Typography>
            <TextField
              type={type}
              value={value}
              required={required}
              onChange={handleInputChange(setter)}
              variant="outlined"
              size="small"
            />
          </FormControl>
        </div>
      ))}
    </Container>
  );
};

export default PersonForm;
