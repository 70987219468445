import React from 'react';
import styled from 'styled-components';
import { Block } from '../../stores/models';
import ContentBlock from '../Layout/ContentBlock';

const Container = styled.div`
  padding: 0 2em;
  display: flex;
  justify-content: center;

  @media ${p => p.theme.breakpoints.mobile} {
    padding: 0;
  }
`;

const Image = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 500px;
`;

interface Props {
  data: Block;
}

const ImageBlock: React.FC<Props> = ({ data }) => {
  return (
    <ContentBlock>
      <Container>
        <Image alt={data.altText} src={data.imageSrc?.url} />
      </Container>
    </ContentBlock>
  );
};

export default ImageBlock;
