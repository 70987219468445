import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { AreaModel } from './AreaModel';
import { BlockModel } from './BlockModel';
import { MediaModel } from './MediaModel';

export const ContentPageModel = types.model({
  id: types.number,
  title: types.string,
  slug: types.string,
  categoryId: types.number,
  areaId: types.maybe(types.number),
  area: types.maybeNull(AreaModel),
  enableAnalytics: types.boolean,
  backgroundImage: types.maybeNull(MediaModel),
  pdfView: types.maybeNull(MediaModel),
  data: types.maybeNull(types.array(BlockModel)),
  data2: types.maybeNull(types.array(BlockModel)),
});

export interface IContentPage extends Instance<typeof ContentPageModel> {}
export interface ContentPage extends SnapshotOut<typeof ContentPageModel> {}
export interface ContentPageIn extends SnapshotIn<typeof ContentPageModel> {}
