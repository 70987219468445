import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const LanguageModel = types.model({
  id: types.identifierNumber,
  code: types.string,
  name: types.string,
});

export interface ILanguageModel extends Instance<typeof LanguageModel> {}
export interface Language extends SnapshotOut<typeof LanguageModel> {}
export interface LanguageIn extends SnapshotIn<typeof LanguageModel> {}
