import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface InputProps {
  validate?: any;
  name: string;
  type?: string;
  id: string;
  label?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  size?: 'small' | 'medium';
  margin?: 'dense' | 'normal';
}

export const Input: FC<InputProps> = ({
  placeholder,
  validate,
  name,
  id,
  label,
  required,
  size,
  margin,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <TextField
          {...input}
          id={id}
          label={label}
          InputLabelProps={{ shrink: true }}
          size={size}
          margin={margin}
          variant="outlined"
          fullWidth
          required={required}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error && t(meta.error)}
          placeholder={placeholder}
          {...rest}
        />
      )}
    </Field>
  );
};
