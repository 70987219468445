import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import styled from 'styled-components';
import { api } from '../../services/api';
import { useStores } from '../../stores';
import { Block, Person } from '../../stores/models';
import { useQueryParams } from '../../utils/hooks';
import FeedbackBox from '../Feedback';
import ContentBlock from '../Layout/ContentBlock';
import { isSlider } from '../../utils/questionnaire';
import { Feedback as FeedbackType } from '../../types/Feedback';
import { observer } from 'mobx-react-lite';

export const DEFAULT_ANSWER_SCALE: [number, number] = [1, 5];

const FeedbackSentContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  svg {
    margin-right: 0.5em;
  }
`;

interface Props {
  data: Block;
}

const composeFeedbackData = (
  data: Block,
  person: Person,
): FeedbackType | undefined => {
  if (data.type === 'QuestionnaireBlock') {
    return {
      personFormPosition: data.personFormPosition,
      person,
      questions: data.items
        ?.filter(({ type }: Block) => type === 'Question')
        .map((question: Block) => ({
          id: question.id ?? question.__index,
          question: question.value,
          questionType: question.questionType,
          isInputVisible: question.isInputVisible,
          sliderStep: question.sliderStep,
          sliderInputDecoration: question.sliderInputDecoration,
          scaleMin: isSlider(question)
            ? question.scaleMin ?? DEFAULT_ANSWER_SCALE[0]
            : undefined,
          scaleMax: isSlider(question)
            ? question.scaleMax ?? DEFAULT_ANSWER_SCALE[1]
            : undefined,
          scaleMinLabel: question.scaleMinLabel,
          scaleMaxLabel: question.scaleMaxLabel,
        })),
    };
  }
  return undefined;
};

const QuestionnaireBlock: React.FC<Props> = observer(({ data }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id: personId } = useQueryParams('id');
  const { id: idInPath } = useParams<{ id: string }>();
  const { navigationpath, tab } = useParams<{ [key: string]: string }>();

  const [feedback, setFeedback] = useState<FeedbackType>();
  const [feedbackSent, setFeedbackSent] = useState(false);

  const {
    productStore: { product },
    contentPageStore: { getPage },
    userStore: { getPersonData },
  } = useStores();

  useEffect(() => {
    const setFeedbackData = async () => {
      const person = await getPersonData(personId);
      setFeedback(composeFeedbackData(data, person));
    };
    setFeedbackData();
  }, [data, getPersonData, personId]);

  // This is a rather ugly/hacky way of finding out which type of
  // content (product/content page) we're dealing with, but it should do the job.
  const getContentProps = () => {
    const slug = tab ?? navigationpath;
    const isProduct = location.pathname.includes('/product');
    const productOrPageId = isProduct ? product?.id : getPage(slug ?? -1)?.id;
    const id = productOrPageId ?? Number(idInPath) ?? -1;
    const type: 'product' | 'page' = isProduct ? 'product' : 'page';
    return { id, type };
  };

  const handleSend = async () => {
    if (feedback) {
      const { id, type } = getContentProps();
      const blockIndex = data.id ?? data.__index;
      const payload = { id, type, blockIndex, feedback, personId };

      const shouldNotStorePersonData =
        data.personFormPosition === 'hidden' ||
        data.personFormPosition === null;

      if (shouldNotStorePersonData) {
        payload.personId = null;
        delete payload.feedback.person;
      }

      const response = await api.sendFeedback(payload);

      if (response.kind === 'ok') {
        setFeedbackSent(true);
      }
    }
  };

  return (
    <>
      <ContentBlock>
        {feedback && (
          <FeedbackBox
            title={data.title}
            feedback={feedback}
            setFeedback={setFeedback}
            onSend={handleSend}
            isSent={feedbackSent}
          />
        )}
      </ContentBlock>

      {feedbackSent && (
        <ContentBlock background="success" wrapperSize="small">
          <FeedbackSentContainer>
            <CheckIcon />
            {t('feedback.feedbackSentNotification')}
          </FeedbackSentContainer>
        </ContentBlock>
      )}
    </>
  );
});

export default QuestionnaireBlock;
