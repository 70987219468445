import styled from 'styled-components';

export const GROUP_COLORS = [
  '#2980b9',
  '#f1c40f',
  '#1abc9c',
  '#e67e22',
  '#8e44ad',
  '#e74c3c',
];

export const StyledLegend = styled.span<{ color?: string }>`
  display: inline-block;
  color: ${p => p.color};
  font-size: 12px;

  @media print {
    color: ${p => p.theme.colors.black};
  }
`;

export const Container = styled.div<{ dataGroupCount?: number }>`
  position: relative;
  user-select: none;
  margin: 1em 0;

  ${p => {
    // Hide duplicate tooltip values from data point where historical values changes to forecast.
    // TODO: Currently this works only for line chanrts where's just one line (=one data group).
    switch (p.dataGroupCount) {
      case 1:
        return `
          .recharts-default-tooltip {
            ul li:nth-child(n + ${p.dataGroupCount + 1}) {
              display: none !important;
            }
          }
        `;
      default:
        return ``;
    }
  }};

  .recharts-legend-item {
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.1rem 0.5rem 0.25rem 0.5rem;
    cursor: pointer;

    :hover {
      background-color: rgba(255, 255, 255, 0.15);
      span {
        text-decoration: underline;
      }
    }

    .is-hidden {
      text-decoration: line-through;
      opacity: 0.4;
    }

    &:has(.is-hidden) {
      opacity: 0.4;
    }
  }

  .chart__action-buttons {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;

    > div.download-button__container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      svg {
        color: white;
      }
    }

    button {
      padding: 0 1em;
    }
  }
`;

export const YAxisUnit = styled.div<{ orientation?: 'left' | 'right' }>`
  position: absolute;
  right: ${p => (p.orientation === 'right' ? 0 : undefined)};
  min-width: ${p => (p.orientation === 'right' ? '68px' : '54px')};
  color: ${p => p.theme.colors.white};
  text-align: ${p => (p.orientation === 'right' ? 'left' : 'right')};
  font-size: 1rem;
`;
