import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

interface Message {
  text: string;
  severity: 'success' | 'error' | 'info' | 'warning';
}

export type ToastMessage = Message | undefined;

interface ToastProps {
  message?: ToastMessage;
  messageSetter: React.Dispatch<React.SetStateAction<ToastMessage>>;
}

const Toast: React.FC<ToastProps> = ({ message, messageSetter }) => {
  const handleClose = () => {
    messageSetter(undefined);
  };

  if (!message) return null;

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={message.severity}>{message.text}</Alert>
    </Snackbar>
  );
};

export default Toast;
