import React, { useEffect } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AreaIcon from '@material-ui/icons/Public';
import IconButton from '@material-ui/core/IconButton';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import { MenuContainer, StyledBadge } from './StyledMenu';
import Tooltip from '../Tooltip';
import { Area } from '../../stores/models';

const AreaMenu: React.FC = observer(() => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const {
    areaStore: { areas, state: areasState, getAreas },
    userStore: { userArea, setArea, token, userLanguage },
  } = useStores();

  useEffect(() => {
    if (areasState === 'NOT_FETCHED') getAreas();
  }, [areasState, getAreas]);

  /**
   * Check that user's selected area is actually available in fetched areas.
   * If not, set default value.
   */
  useEffect(() => {
    if (areasState === 'FETCHED') {
      const areaCodes = areas.map(({ code }) => code);
      if (!areaCodes.includes(userArea)) {
        setArea(areas[0]?.code ?? 'fi');
      }
    }
  }, [areas, areasState, getAreas, setArea, userArea]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * Change domain when area changes.
   *
   * Some query parameters needs to be passed to the new domain
   * in order to, e.g., keep the user logged in.
   */
  const handleChange = ({ code, domain }: Area) => {
    if (window?.location) {
      const queryParams = [
        `AUTH_TOKEN=${token}`,
        `AREA=${code}`,
        `LANG_ID=${userLanguage.languageId}`,
        `LANG_CODE=${userLanguage.languageCode}`,
      ];
      const areaDomain = `${domain}?${queryParams.join('&')}`;
      window.location.assign(areaDomain);
    }
  };

  if (areas.length <= 1) return null;

  return (
    <MenuContainer>
      {t('common.area.title')}
      <Tooltip title={t(`common.area.tooltip.${userArea}`) ?? ''}>
        <div>
          <IconButton
            aria-label="area-menu"
            onClick={handleOpenMenu}
            aria-controls="area-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            size="small"
          >
            <StyledBadge
              badgeContent={userArea.toUpperCase() ?? undefined}
              color="secondary"
            >
              <AreaIcon fontSize="large" style={{ color: 'white' }} />
            </StyledBadge>
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="area-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {!!areas?.length &&
          areas.map(area => (
            <MenuItem
              onClick={() => handleChange(area)}
              key={area.id}
              value={area.code}
            >
              {t(`common.area.${area.code}`)}
            </MenuItem>
          ))}
      </Menu>
    </MenuContainer>
  );
});

export default AreaMenu;
