export enum ERROR {
  LOGIN_ERROR = 'errors.loginError',
  GENERAL_ERROR = 'errors.generalError',
  FETCH_USER_ERROR = 'errors.fetchUserError',
  CHANGE_PASSWORD_ERROR = 'errors.changePasswordError',
  STATISTICS_SEND_ERROR = 'errors.statisticsSentError',
}

export enum SUCCESS {
  CHANGE_PASSWORD_SUCCESS = 'successes.passwordChanged',
  RESET_PASSWORD_SUCCESS = 'successes.linkSent',
  STATISTICS_SEND_SUCCESS = 'successes.statisticsSent',
}

export const LOCALE = 'locale';

export const LANGUAGES = ['fi', 'en'];
export const DEFAULT_LANGUAGE = 'fi';
