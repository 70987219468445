import {
  Instance,
  types,
  flow,
  cast,
  getParent,
  getSnapshot,
} from 'mobx-state-tree';
import { translate } from '../i18n/translate';
import { api } from '../services/api';
import STORAGE from '../utils/storage';
import { ERROR, SUCCESS } from '../constants/constants';
import { UserModel } from './models/UserModel';
import { LanguageModel } from './models/LanguageModel';
import { Person } from './models';
import { triggerEvent } from '../utils/events';
import { getFromStorageOrQueryParams } from '../utils/common';
import { RootStore } from './RootStore';

const LoginStates = [
  'LOGGED_IN' as const,
  'LOGGED_OUT' as const,
  'IN_PROGRESS' as const,
];
// prettier-ignore
const UsersStates = ['NOT_FETCHED' as const, 'FETCHING' as const, 'FETCHED' as const, 'ERROR' as const];
const LanguagesStates = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
];

export const UserStore = types
  .model({
    state: types.enumeration('State', LoginStates),
    token: types.optional(
      types.string,
      getFromStorageOrQueryParams('AUTH_TOKEN') ?? '',
    ),
    user: types.maybe(UserModel),
    usersState: types.enumeration('State', UsersStates),
    availableLanguages: types.maybe(types.array(LanguageModel)),
    availableLanguagesState: types.enumeration('State', LanguagesStates),
    selectedArea: types.optional(
      types.string,
      STORAGE.read({ key: 'AREA' }) ?? 'fi',
    ),
  })
  .views(self => ({
    get username() {
      return self.user?.username;
    },
    get isLoggedIn() {
      return self.state === 'LOGGED_IN' || !!self.token?.length;
    },
    get userLanguage() {
      const languageId = self.user?.languageId;
      const languageCode = self.availableLanguages?.find(
        ({ id }) => id === languageId,
      )?.code;
      return { languageId, languageCode };
    },

    get userArea() {
      return self.selectedArea;
    },
  }))
  .actions(self => {
    const setToken = function (token: string) {
      STORAGE.write({ key: 'AUTH_TOKEN', value: token });
      self.token = token;
    };

    const login = flow(function* (params: Api.Req.Login) {
      const { notificationStore } = getParent(self) as RootStore;
      self.state = 'IN_PROGRESS';

      const languageId = STORAGE.read({ key: 'LANGUAGE' })?.id;

      const response: Api.Response<Api.Res.Login> = yield api.login({
        ...params,
        languageId,
        username: params.username.toLowerCase().trim(),
      });

      if (response.kind === 'ok') {
        self.token = response.data.token;
        self.user = cast({
          id: response.data.userId,
          username: response.data.username,
          name: response.data.name,
          company: response.data.company,
          languageId: response.data.languageId,
        });
        self.availableLanguages = cast(response.data.availableLanguages);
        self.state = 'LOGGED_IN';
        self.usersState = 'FETCHED';
        triggerEvent('login');
      } else {
        notificationStore.setError(translate(ERROR.GENERAL_ERROR) ?? '');
        self.state = 'LOGGED_OUT';
      }
    });

    const logout = flow(function* () {
      yield triggerEvent('logout');
      yield api.logout({});
      STORAGE.write({ key: 'AUTH_TOKEN', value: null });
      self.token = '';
      self.user = undefined;
      self.state = 'LOGGED_OUT';
      self.usersState = 'NOT_FETCHED';
      self.availableLanguages = undefined;
      self.availableLanguagesState = 'NOT_FETCHED';
    });

    const getMe = flow(function* (params: Api.Req.GetMe = {}) {
      const response: Api.Response<Api.Res.GetMe> = yield api.getMe(params);

      if (response.kind === 'ok') {
        self.user = cast({
          id: response.data.id,
          username: response.data.username,
          name: response.data.name,
          company: response.data.company,
          languageId: response.data.languageId,
        });
        self.state = 'LOGGED_IN';
      }
    });

    const updateMe = flow(function* (params: Api.Req.UpdateMe) {
      const response: Api.Response<Api.Res.GetMe> = yield api.updateMe(params);

      if (response.kind === 'ok') {
        self.user = cast({
          id: response.data.id,
          username: response.data.username,
          name: response.data.name,
          company: response.data.company,
          languageId: response.data.languageId,
        });
      }
    });

    const passwordResetRequest = flow(function* (params: { username: string }) {
      const { notificationStore } = getParent(self) as RootStore;

      const response = yield api.passwordResetRequest(params);

      if (response.kind !== 'ok') {
        notificationStore.setError(translate(ERROR.FETCH_USER_ERROR) ?? '');
      } else {
        notificationStore.clearNotification();
      }
    });

    const passwordReset = flow(function* (params: Api.Req.PasswordReset) {
      const { notificationStore } = getParent(self) as RootStore;

      const response = yield api.passwordReset(params);

      if (response.kind !== 'ok') {
        notificationStore.setError(translate(ERROR.FETCH_USER_ERROR) ?? '');
      } else {
        notificationStore.setSuccess(
          translate(SUCCESS.CHANGE_PASSWORD_SUCCESS) ?? '',
        );
      }
    });

    const getLanguages = flow(function* () {
      const response: Api.Response<Api.Res.GetLanguages> =
        yield api.getLanguages();
      self.availableLanguagesState = 'FETCHING';

      if (response.kind === 'ok') {
        self.availableLanguages = cast(response.data);
        self.availableLanguagesState = 'FETCHED';
      } else {
        self.availableLanguagesState = 'ERROR';
      }
    });

    const setArea = (areaCode: string) => {
      self.selectedArea = areaCode;
      STORAGE.write({
        key: 'AREA',
        value: areaCode,
      });
    };

    /**
     * Get person data.
     *
     * Primary option is to use person object fetched from API.
     * If existing person is not found, or person ID (uuid) was not provided,
     * fill fields with logged-in user's data instead.
     * Otherwise leave all fields null.
     */
    const getPersonData = flow(function* (personId?: string | null) {
      if (!self.user && self.token.length) yield getMe();
      const user = self.user ? getSnapshot(self.user) : undefined;

      let personFromApi: Person | undefined = undefined;
      if (personId) {
        const response: Api.Response<Api.Res.GetPerson> = yield api.getPerson({
          id: personId,
        });
        if (response.kind === 'ok') {
          personFromApi = response.data;
        }
      }

      const person: Person = {
        id: personFromApi?.id ?? null,
        name: personFromApi?.name ?? user?.name ?? null,
        email: personFromApi?.email ?? user?.username ?? null,
        company: personFromApi?.company ?? user?.company?.name ?? null,
      };
      return person;
    });

    return {
      setToken,
      login,
      logout,
      getMe,
      updateMe,
      passwordResetRequest,
      passwordReset,
      getLanguages,
      setArea,
      getPersonData,
    };
  });

export interface IUserStore extends Instance<typeof UserStore> {}

export default UserStore;
