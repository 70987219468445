import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Ul = styled.ul<{ textColor?: string }>`
  list-style-type: none;
  padding: 0;
  width: 100%;

  li {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;

    p {
      color: ${p => p.textColor};
    }

    :not(:first-child) {
      border-top: 1px solid ${p => p.theme.colors.grey};
    }

    @media ${p => p.theme.breakpoints.mobile} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const Bullet = styled.div<{ accent?: boolean }>`
  min-height: 15px;
  max-height: 15px;
  min-width: 15px;
  max-width: 15px;
  border-radius: 100px;
  background-color: ${p => (p.accent ? p.theme.colors.greyDarker : '#95C7ED')};
  margin: 8px;
  margin-right: 40px;

  @media ${p => p.theme.breakpoints.mobile} {
    margin-right: 20px;
  }
`;

interface Props {
  items: string[];
  textColor?: string;
}

const List: React.FC<Props> = ({ items, textColor }) => {
  return (
    <Ul textColor={textColor}>
      {items.map((item, i) => (
        <li key={`${i}-${item}`}>
          <Bullet accent />
          <Typography variant="body1">{item}</Typography>
        </li>
      ))}
    </Ul>
  );
};

export default List;
