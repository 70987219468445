import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ContentBlock from './Layout/ContentBlock';

const Container = styled.div<{ verticalPadding?: number | string }>`
  padding-top: ${p => p.verticalPadding};
  padding-bottom: ${p => p.verticalPadding};
  text-align: center;
`;

interface Props {
  verticalPadding?: number | string;
}

const Spinner: React.FC<Props> = ({ verticalPadding }) => (
  <ContentBlock>
    <Container verticalPadding={verticalPadding}>
      <CircularProgress size={75} thickness={6} color="secondary" />
    </Container>
  </ContentBlock>
);

export default Spinner;
