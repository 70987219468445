import {
  Button as MuiButton,
  ButtonProps,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  button: {
    padding: '0.5em 1.5em',
    color: '#22304E',
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    '&:active': {
      backgroundColor: '#1F7BB6',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#1F7BB6',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: '#1F7BB6',
      color: 'white',
    },
    '&.selected': {
      backgroundColor: '#1F7BB6',
      color: 'white',
    },
  },
}));

interface Props extends ButtonProps {
  label: string;
}

const Button: React.FC<Props> = ({
  label,
  size = 'large',
  variant = 'contained',
  color = 'primary',
  className,
  onClick,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiButton
      className={`${classes.button} ${className}`}
      size={size}
      variant={variant}
      color={color}
      disableElevation
      onClick={onClick}
      {...props}
    >
      {label}
    </MuiButton>
  );
};

export default Button;
