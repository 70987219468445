import styled from 'styled-components';

export type WrapperSize = 'small' | 'default';

export const Wrapper = styled.div<{
  noMargin?: boolean;
  transparentBg?: boolean;
  rounded?: boolean | 'allCorners';
  wrapperSize?: WrapperSize;
}>`
  padding: ${p => (p.noMargin ? 0 : '1em')} 0;
  max-width: ${p => (p.wrapperSize === 'small' ? 1040 : 1100)}px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;

  border-top-left-radius: ${p => (p.rounded ? 8 : 0)}px;
  border-top-right-radius: ${p => (p.rounded ? 8 : 0)}px;
  border-bottom-left-radius: ${p => (p.rounded === 'allCorners' ? 8 : 0)}px;
  border-bottom-right-radius: ${p => (p.rounded === 'allCorners' ? 8 : 0)}px;

  background-color: ${p =>
    p.transparentBg ? 'transparent' : p.theme.colors.white};

  @media ${p => p.theme.breakpoints.tablet} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
