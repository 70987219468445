import React from 'react';
import { Block } from '../../stores/models';
import ContentBlock from '../Layout/ContentBlock';
import List from '../List';

interface Props {
  data: Block;
}

const BulletedTextBlock: React.FC<Props> = ({ data }) => {
  return (
    <ContentBlock noMargin wrapperSize="small">
      <List items={[data.body]} />
    </ContentBlock>
  );
};

export default BulletedTextBlock;
