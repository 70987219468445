import { ChartData, ChartDataGroup, ChartType, ZoomState } from './SimpleChart';

// Show X axis labels in angle if any of them length are above this threshold
const LONG_LABEL_THRESHOLD = 10;

export const hasLongLabels = (data?: any[]) => {
  return data?.reduce((acc, { name }) => {
    return name && name.length > LONG_LABEL_THRESHOLD ? true : acc;
  }, false);
};

export const initialZoomState: ZoomState = {
  refAreaLeft: '',
  refAreaRight: '',
  left: 'dataMin',
  right: 'dataMax',
  top: 'dataMax+1',
  bottom: 'dataMin-1',
};

export const formatData = (
  type: ChartType,
  data: ChartData[],
  groups?: ChartDataGroup[],
  hiddenDataGroups: string[] = [],
) => {
  if (!groups?.length) return data;

  const isLineChart = type === 'lines';

  // Make a copy of data to avoid mutating original data
  const formattedData = [...data.map(item => ({ ...item }))];

  // Map through each data group from data
  groups.forEach(({ dataKey, dataKeys }) => {
    if (dataKeys?.length) {
      const hasHistoricalKeys = dataKeys.some(k => k.includes('historical'));

      dataKeys.forEach(dataKey => {
        let previousIsForecast: boolean = false;
        formattedData.forEach(dataItem => {
          const { isForecast } = dataItem;

          const dataKeyParts = dataKey.split('.');
          const parentAccessor = dataKeyParts[0];
          const valueAccessor = dataKeyParts.length > 1 ? dataKeyParts[1] : '';
          const hasValue =
            dataItem[parentAccessor]?.[valueAccessor] !== undefined;

          // If the data group is hidden, remove it from data
          if (hiddenDataGroups.includes(dataKey)) {
            delete dataItem[parentAccessor];
            return;
          }

          // If the data group is a forecast, remove historical value and vice versa
          if (!isLineChart) {
            // Make sure the forecast value is present only if the record actually is a forecast
            if (!isForecast && valueAccessor === 'forecast' && hasValue)
              dataItem[parentAccessor][valueAccessor] = null;
            // Make sure the historical value is present only if the record actually is NOT a forecast
            if (isForecast && valueAccessor === 'historical' && hasValue)
              dataItem[parentAccessor][valueAccessor] = null;
          }

          // A bit dirty way of fixing possibly dirty data:
          // Sometimes there can be falsy data keys originated from automatic web scraper or something...
          // E.g. uploaded data items in general are in format
          // { ..., group0: { historical: x, forecast: null }, isForecast: false }
          // BUT there is ALSO some items in format { ..., group0: x },
          // where instead of object there is a plain value in group's key.
          // -> In this special case, transform falsy value to same format as other values are.
          //    Use previous item's isForecast value to define if the value should be forecast or historical.
          const isPlainValue = typeof dataItem[parentAccessor] === 'number';
          if (hasHistoricalKeys && isPlainValue) {
            dataItem.isForecast = previousIsForecast;
            dataItem[parentAccessor] = {
              historical: previousIsForecast ? null : dataItem[parentAccessor],
              forecast: previousIsForecast ? dataItem[parentAccessor] : null,
            };
          }

          previousIsForecast = isForecast;
        });
      });
    } else if (dataKey) {
      // In case of a single data key, consider that value can be either
      // - a plain number value or
      // - an object of type { historical: number | null, forecast: number | null }
      formattedData.forEach(dataItem => {
        const value = dataItem[dataKey];
        delete dataItem.isForecast;
        dataItem[dataKey] = Number(
          value?.forecast ?? value?.historical ?? value,
        );
      });
    }
  });

  return formattedData;
};
