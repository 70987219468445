import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { DateTime } from 'luxon';
import { Link } from '../Link';
import { round } from '../../utils/math';
import i18n from '../../i18n';
import NoPrint from '../NoPrint';

export const CellRenderers = {
  link:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original } }: any) =>
      !cell.value ? null : (
        <Typography variant="body1" style={cell.column?.customStyle}>
          <Link to={linkFn(original)}>{cell.value}</Link>
        </Typography>
      ),

  dateLink:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original } }: any) =>
      !cell.value ? null : (
        <Typography variant="body1" style={cell.column?.customStyle}>
          <Link to={linkFn(original)}>
            {cell.value !== undefined
              ? DateTime.fromISO(cell.value).toFormat('dd.MM.yyyy')
              : ''}
          </Link>
        </Typography>
      ),

  priceExpectation:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original } }: any) => {
      if (!cell.value) return null;

      let label = '';
      let transform: string | undefined = undefined;
      let color: string | undefined = undefined;

      switch (cell.value) {
        case 'DECREASING':
          label = i18n.t('products.DECREASING');
          transform = 'rotate(45deg)';
          color = '#038500';
          break;
        case 'INCREASING':
          label = i18n.t('products.INCREASING');
          transform = 'rotate(315deg)';
          color = '#B10000';
          break;
        case 'STABLE':
          label = i18n.t('products.STABLE');
          transform = undefined;
          color = '#DA8300';
          break;
        default:
        // do nothing
      }

      return (
        <Link to={linkFn(original)}>
          <Typography
            variant="body1"
            style={{ display: 'flex', ...cell.column?.customStyle }}
          >
            <ArrowForward style={{ transform, color }} />
            {label}
          </Typography>
        </Link>
      );
    },

  forecast:
    (linkFn: (row: any) => string) =>
    ({ cell, row: { original, allCells } }: any) => {
      if (!cell.value && cell.value !== 0) {
        return null;
      }

      const percentValue =
        typeof cell.value === 'number' ? round(cell.value * 100) : null;
      const nonBreakableSpace = `\xa0`;
      const valueSuffix = `${nonBreakableSpace}%`;
      const renderValue =
        percentValue !== null ? `${percentValue}${valueSuffix}` : '';

      return (
        <Typography
          variant="body1"
          style={{
            backgroundColor:
              allCells[1].value === 'DECREASING'
                ? '#C1DFC0'
                : allCells[1].value === 'STABLE'
                ? '#F6DCB4'
                : '#FFD1D1',
            padding: '16px',
          }}
        >
          <Link to={linkFn(original)}>{renderValue}</Link>
        </Typography>
      );
    },

  linkOnClick:
    (onClick: (row: any) => void) =>
    ({ cell, row: { original } }: any) =>
      !cell.value ? null : (
        <Typography variant="body1" style={cell.column?.customStyle}>
          <Link onClick={() => onClick(original)}>{cell.value}</Link>
        </Typography>
      ),

  linkWrapper:
    (
      linkFn: (row: any) => string,
      cellRenderer: (data: any) => JSX.Element | string,
    ) =>
    (data: any) =>
      <Link to={linkFn(data.row.original)}>{cellRenderer(data)}</Link>,

  text: ({ cell }: any) =>
    !cell.value && cell.value !== 0 ? null : (
      <Typography variant="body1" style={cell.column?.customStyle}>
        {cell.value}
      </Typography>
    ),

  textFn:
    (fn: (cell: any, row: any) => string) =>
    ({ cell, row: { original } }: any) =>
      (
        <Typography variant="body1" style={cell.column?.customStyle}>
          {fn(cell, original)}
        </Typography>
      ),

  editIcon:
    (linkFn: (row: any) => string) =>
    ({ row: { original } }: any) =>
      (
        <NoPrint>
          <Link to={linkFn(original)}>
            <IconButton aria-label="edit">
              <Edit />
            </IconButton>
          </Link>
        </NoPrint>
      ),

  chevronRight:
    (linkFn: (row: any) => string) =>
    ({ row: { original } }: any) =>
      (
        <NoPrint>
          <Link to={linkFn(original)}>
            <IconButton aria-label="edit" style={{ padding: '0 16px' }}>
              <KeyboardArrowRight />
            </IconButton>
          </Link>
        </NoPrint>
      ),

  deleteIcon:
    (deleteFn: (row: any) => any) =>
    ({ row: { original } }: any) =>
      (
        <NoPrint>
          <IconButton aria-label="delete" onClick={deleteFn(original)}>
            <Delete />
          </IconButton>
        </NoPrint>
      ),
};
