import { Instance, SnapshotOut, onSnapshot, types } from 'mobx-state-tree';
import { UserStore } from './UserStore';
import { NotificationStoreModel } from './NotificationStore';
import STORAGE from '../utils/storage';
import ProductStore from './ProductStore';
import NavigationStore from './NavigationStore';
import ContentPageStore from './ContentPageStore';
import AreaStore from './AreaStore';

// Set to true, if you want to save entire store's snapshot to the local/session storage
const SAVE_TO_STORAGE = false;

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  userStore: types.optional(UserStore, { state: 'LOGGED_OUT', usersState: 'NOT_FETCHED', availableLanguagesState: 'NOT_FETCHED' }),
  navigationStore: types.optional(NavigationStore, { state: 'NOT_FETCHED' }),
  notificationStore: types.optional(NotificationStoreModel, {}),
  productStore: types.optional(ProductStore, { state: 'NOT_FETCHED', currentProductState: 'IDLE', products: [], categories: [] }),
  contentPageStore: types.optional(ContentPageStore, { state: 'IDLE' }),
  areaStore: types.optional(AreaStore, { state: 'NOT_FETCHED' }),
})

export interface RootStore extends Instance<typeof RootStoreModel> {}

export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}

const storageState = SAVE_TO_STORAGE
  ? STORAGE.read({ key: 'ROOT_STATE' })
  : undefined;

// Get initial state from local storage (or initialize empty state)
export const rootStore = RootStoreModel.create(
  RootStoreModel.is(storageState) ? storageState : {},
);

// Update state in local storage on every snapshot
onSnapshot(rootStore, snapshot => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Snapshot:', snapshot);
  }
  if (SAVE_TO_STORAGE) {
    STORAGE.write({ key: 'ROOT_STATE', value: snapshot });
  }
});
