import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceArea,
  YAxisProps,
} from 'recharts';
import Button from '../FormControls/Button';
import { ExportData } from '../ExportData';
import NoPrint from '../NoPrint';
import Tooltip from '../Tooltip';
import {
  formatData,
  hasLongLabels,
  initialZoomState,
} from './simpleChart.utils';
import {
  Container,
  GROUP_COLORS,
  StyledLegend,
  YAxisUnit,
} from './SimpleChart.styled';

// Returns the base data key from the given data key, e.g. 'group0' from 'group0.historical'
const getBaseDataKey = (dataKey: string) => dataKey.split('.')[0];

// Returns all data keys from the given data groups
const getAllDataKeys = (groups: ChartDataGroup[]) =>
  groups
    .map(({ dataKey, dataKeys }) =>
      dataKey ? [dataKey, ...(dataKeys ?? [])] : dataKeys ?? [],
    )
    .flat();

// Returns the data keys that include the given key
const groupsThatIncludes = (
  key: string,
  dataGroups: ChartDataGroup[] | undefined,
) =>
  getAllDataKeys(dataGroups ?? []).filter(groupKey => groupKey.includes(key));

// Check if key is an additional currency.
const isAdditionalCurrency = (key: string) =>
  key.includes('_eur') || key.includes('_usd') || key.includes('_sek');

const getCurrencySymbol = (key: string) =>
  key.includes('_eur')
    ? '€'
    : key.includes('_usd')
    ? '$'
    : key.includes('_sek')
    ? 'kr'
    : '';

// Slices the given data from the given from and to values
const sliceData = (
  data: ChartData[],
  from: ZoomValue,
  to: ZoomValue,
): ChartData[] => {
  const _data = data.map(({ name }) => name);
  return data.slice(
    from === 'dataMin' ? 0 : _data.indexOf(from),
    to === 'dataMax' ? data.length : _data.indexOf(to) + 1,
  );
};

// Zooms the data based on the given zoom state
const zoomData = (
  data: ChartData[],
  zoomState: ZoomState,
): { zoomedData?: ChartData[]; zoomState: ZoomState } => {
  let { refAreaLeft, refAreaRight } = zoomState;

  if (refAreaLeft === refAreaRight || refAreaRight === '') {
    return {
      zoomState: { ...zoomState, refAreaLeft: '', refAreaRight: '' },
    };
  }

  // xAxis domain
  if (refAreaLeft > refAreaRight) {
    [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
  }

  return {
    zoomedData: sliceData(data, refAreaLeft, refAreaRight),
    zoomState: {
      ...zoomState,
      refAreaLeft: '',
      refAreaRight: '',
      left: refAreaLeft,
      right: refAreaRight,
    },
  };
};

export interface ChartDataGroup {
  dataKey?: string | null;
  dataKeys?: string[] | null;
  isForecast?: boolean | null;
  name?: string;
  color?: string;
}

export type ChartType = 'lines' | 'bars';
export type ChartData = any;
export type ZoomValue = string | number;
export type ZoomState = { [key: string]: ZoomValue };

interface Props {
  title?: string;
  data: ChartData[];
  dataGroups?: ChartDataGroup[];
  xAxisDataKey?: string;
  type?: ChartType;
  gridColor?: string;
  noLegend?: boolean;
  noTooltip?: boolean;
  leftAxisUnit?: string;
}

export const SimpleChart: React.FC<Props> = ({
  title,
  data,
  dataGroups,
  xAxisDataKey = 'name',
  type = 'bars',
  gridColor = '#bbb',
  noLegend = false,
  noTooltip = false,
  leftAxisUnit,
}) => {
  const { t } = useTranslation();
  const chartRef = useRef<HTMLDivElement>(null);

  // Initial hidden data groups
  const initiallyHiddenDataGroups = useMemo(
    () => [
      // Additional currencies are hidden by default
      ...groupsThatIncludes('_eur', dataGroups),
      ...groupsThatIncludes('_usd', dataGroups),
      ...groupsThatIncludes('_sek', dataGroups),
    ],
    [dataGroups],
  );

  const [initialData, setInitialData] = useState<ChartData[]>([]);
  const [zoomedData, setZoomedData] = useState<ChartData[]>([]);
  const [zoomState, setZoomState] = useState(initialZoomState);
  const [hiddenDataGroups, setHiddenDataGroups] = useState<string[]>(
    initiallyHiddenDataGroups,
  );
  const [rightAxisUnit, setRightAxisUnit] = useState<string>();

  const { refAreaLeft, refAreaRight, left, right, top, bottom } = zoomState;

  // Set initial (and zoomed) data when data changes
  useEffect(() => {
    const formattedData = formatData(type, data, dataGroups, hiddenDataGroups);
    setInitialData(formattedData);
    setZoomedData(sliceData(formattedData, left, right));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataGroups, hiddenDataGroups, type]);

  const isZoomed = initialData.length > zoomedData.length;

  if (!dataGroups) return null;

  const toggleHiddenDataGroup = (dataKey: string) => {
    const baseKey = getBaseDataKey(dataKey);
    const baseKeys = hiddenDataGroups.map(key => getBaseDataKey(key));

    const isInitiallyHidden = initiallyHiddenDataGroups.includes(dataKey);

    if (hiddenDataGroups.includes(dataKey) || baseKeys.includes(baseKey)) {
      const uniqueHiddenDataGroups = Array.from(
        new Set([
          // If dataKey is initially hidden (="additional currency"),
          // all other initially hidden data groups should be hidden
          ...(isInitiallyHidden ? initiallyHiddenDataGroups : []),
          // Otherwise just keep as it is
          ...hiddenDataGroups,
        ]),
      );

      // Data group (dataKey) is already hidden, remove it from hiddenDataGroups
      setHiddenDataGroups(
        uniqueHiddenDataGroups.filter(
          // Also remove all dataKeys that belong to the same baseKey
          key => key !== dataKey && getBaseDataKey(key) !== baseKey,
        ),
      );

      const currency = getCurrencySymbol(dataKey);

      setRightAxisUnit((leftAxisUnit ?? '').replace(/[$€]/g, currency));
    } else {
      // Hide data group (dataKey)
      setHiddenDataGroups([...hiddenDataGroups, dataKey]);
      if (isInitiallyHidden) {
        setRightAxisUnit(undefined);
      }
    }
  };

  const formatCsvExportData = (
    data: ChartData[],
    dataGroups: ChartDataGroup[] | undefined,
  ) => {
    const visibleDataGroups = dataGroups?.filter(({ dataKey, dataKeys }) => {
      const allDataKeys = dataKeys ? [dataKey, ...dataKeys] : [dataKey];
      return !allDataKeys
        .filter(Boolean)
        .some(key => key && hiddenDataGroups.includes(key));
    });

    // Collect data points per data groups
    let csvDataValues = data.map(row => {
      const rowKeys = Object.keys(row);

      // Find dataGroups per data entry, drop other props
      const groupValues = rowKeys
        .map(key => key.startsWith('group') && row[key])
        .filter(Boolean);

      // Construct data row for CSV
      return [
        row.name,
        groupValues.map(data => [
          typeof data === 'number'
            ? data
            : row.isForecast
            ? data.forecast
            : data.historical,
        ]),
      ].flat();
    });

    // Create and insert title row
    const titleRow = ['', visibleDataGroups?.map(group => group.name)].flat();

    csvDataValues.unshift(titleRow);

    return csvDataValues;
  };

  const zoom = (data: ChartData[], zoomState: ZoomState) => {
    const { zoomedData, zoomState: newZoomState } = zoomData(data, zoomState);
    if (zoomedData) setZoomedData(zoomedData);
    if (newZoomState) setZoomState(newZoomState);
  };

  const zoomOut = () => {
    setZoomedData(initialData);
    setZoomState(initialZoomState);
  };

  const handleMouseDown = (e: any) => {
    if (e?.activeLabel) {
      setZoomState({ ...zoomState, refAreaLeft: e.activeLabel });
    }
  };

  const handleMouseMove = (e: any) => {
    if (e?.activeLabel && zoomState.refAreaLeft) {
      setZoomState({ ...zoomState, refAreaRight: e.activeLabel });
    }
  };

  const handleMouseUp = () => {
    zoom(initialData, zoomState);
  };

  const renderLegend = (value: string, legend: any) => {
    const isHidden = hiddenDataGroups.includes(legend.dataKey);

    return (
      <Tooltip
        title={t(`common.${isHidden ? 'show' : 'hide'}`)}
        placement="bottom"
      >
        <StyledLegend color={gridColor} className={isHidden ? 'is-hidden' : ''}>
          {value}
        </StyledLegend>
      </Tooltip>
    );
  };

  // Text properties for both axis (x/y)
  const axisTickProps = { fill: gridColor, fontSize: '11px' };

  // If X axis labels should be shown in angle, use these props for XAxis
  const angleAxisProps = {
    angle: 13,
    interval: 0,
    dx: -3,
    dy: -5,
    height: 40,
    textAnchor: 'start',
  };

  const yAxisProps: YAxisProps = {
    tick: axisTickProps,
    stroke: 'transparent',
    domain: [bottom, top],
    scale: 'linear',
  };

  // Common chart components used for all chart types (bars/lines/etc.)
  const commonChartComponents = (
    <>
      <CartesianGrid vertical={false} stroke={gridColor} />
      <XAxis
        padding={{ left: 20, right: 20 }}
        dataKey={xAxisDataKey}
        tick={axisTickProps}
        stroke="transparent"
        domain={[left, right]}
        {...(hasLongLabels(data) ? angleAxisProps : {})}
      />

      <YAxis yAxisId="left" {...yAxisProps} />
      <YAxis yAxisId="right" orientation="right" {...yAxisProps} />

      {!noTooltip && <ChartTooltip cursor={{ fill: 'transparent' }} />}
      {!noLegend && (
        <Legend
          formatter={renderLegend}
          verticalAlign="top"
          wrapperStyle={{ top: 0 }}
          onClick={legend => toggleHiddenDataGroup(legend.dataKey)}
        />
      )}
      <ReferenceLine y={0} stroke={gridColor} strokeWidth={2} yAxisId="left" />

      {refAreaLeft && refAreaRight ? (
        <ReferenceArea
          x1={refAreaLeft}
          x2={refAreaRight}
          strokeOpacity={0.3}
          yAxisId="left"
        />
      ) : null}
    </>
  );

  const chartMargin = { top: 20, right: 20 };

  const renderLineChart = () => (
    <LineChart
      data={zoomedData}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      margin={chartMargin}
    >
      {commonChartComponents}

      {dataGroups?.map(({ name, dataKey, dataKeys, color }, i) => {
        const _dataKeys = [...(dataKey ? [dataKey] : []), ...(dataKeys ?? [])];
        // Draw multiple lines: one for each key in dataKeys array (including possible dataKey attribute)
        return _dataKeys.map(key => {
          const isDashed = key.includes('forecast');
          return (
            <Line
              key={key}
              dataKey={key}
              name={name}
              legendType={isDashed ? 'none' : 'circle'}
              strokeDasharray={isDashed ? '3 3' : undefined}
              stroke={color ?? GROUP_COLORS[i % GROUP_COLORS.length]}
              type="monotone"
              strokeWidth={3}
              dot={false}
              // If key group is additional currency, use right Y axis
              yAxisId={isAdditionalCurrency(key) ? 'right' : 'left'}
            />
          );
        });
      })}
    </LineChart>
  );

  const renderBarChart = () => (
    <BarChart
      data={zoomedData}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      margin={chartMargin}
    >
      {commonChartComponents}

      {dataGroups?.map(({ name, dataKey, dataKeys, color }, i) => {
        const _dataKeys = [...(dataKey ? [dataKey] : []), ...(dataKeys ?? [])];

        // Drow multiple bars: one for each key in dataKeys array
        return _dataKeys.map(key => {
          return (
            <Bar
              key={key}
              name={name}
              dataKey={key}
              legendType={key.includes('forecast') ? 'none' : undefined}
              fill={color ?? GROUP_COLORS[i % GROUP_COLORS.length]}
              type="monotone"
              barSize={10}
              stackId={name}
              yAxisId="left"
            />
          );
        });
      })}
    </BarChart>
  );

  const renderChart = () => {
    switch (type) {
      case 'lines':
        return renderLineChart();
      case 'bars':
        return renderBarChart();
      default:
        return <></>;
    }
  };

  return (
    <Container dataGroupCount={dataGroups.length}>
      {leftAxisUnit && <YAxisUnit>{leftAxisUnit}</YAxisUnit>}

      {rightAxisUnit && (
        <YAxisUnit orientation="right">{rightAxisUnit}</YAxisUnit>
      )}

      <div ref={chartRef}>
        <ResponsiveContainer width="100%" height={380}>
          {renderChart()}
        </ResponsiveContainer>
      </div>

      <NoPrint>
        <div className="chart__action-buttons">
          <ExportData
            data={formatCsvExportData(zoomedData, dataGroups)}
            chartRef={chartRef}
            title={title}
            imageDownload
            eventType="chart_upload"
          />
          <Button
            type="button"
            label={t('charts.resetZoom')}
            onClick={zoomOut}
            size="small"
            disabled={!isZoomed}
          />
        </div>
      </NoPrint>
    </Container>
  );
};

export default SimpleChart;
