import React from 'react';
import { Typography } from '@material-ui/core';
import { Block } from '../../stores/models';
import ContentBlock from '../Layout/ContentBlock';
import styled from 'styled-components';
import { formatDateTime } from '../../utils/date';
import LinkIcon from '@material-ui/icons/Web';

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 3rem 2rem;

  @media ${p => p.theme.breakpoints.tablet} {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    margin-left: 0;
    margin-right: 0;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;

    > div {
      &.left-link,
      &.right-link {
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      &.left-link {
        text-align: right;
      }
      &.timeline {
        width: 130px;
      }
    }

    a {
      color: ${p => p.theme.colors.black};
      display: block;
      padding: 0.3rem;
      :hover {
        text-decoration: none;
      }
    }
  }
`;

const Timeline = styled.div<{ position: 'top' | 'bottom'; isShort: boolean }>`
  position: absolute;
  height: 40px;
  left: ${p => (p.isShort ? '15px' : '50px')};
  top: ${p => (p.position === 'top' ? '-40px' : undefined)};
  bottom: ${p => (p.position === 'bottom' ? '-40px' : undefined)};
  border-left: 1px dashed ${p => p.theme.colors.primary};
`;

const TimelineEnd = styled.div<{
  position: 'top' | 'bottom';
  isShort: boolean;
}>`
  position: absolute;
  width: 11px;
  height: 11px;
  top: ${p => (p.position === 'top' ? '-42px' : undefined)};
  bottom: ${p => (p.position === 'bottom' ? '-42px' : undefined)};
  left: ${p => (p.isShort ? '10px' : '45px')};

  border-radius: 50px;
  background-color: ${p => p.theme.colors.primary};
`;

const Timestamp = styled.div<{ visible: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 31px;
  width: ${p => (p.visible ? '101px' : '31px')};
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;

  background-color: ${p => p.theme.colors.white};
  border-color: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.primary};

  border-width: 2px;
  border-style: solid;
  border-radius: 50px;

  font-weight: bold;
  font-size: 0.9rem;
`;

interface LinkTimelineProps {
  label?: string;
  isTop?: boolean;
  isBottom?: boolean;
}

const LinkTimeline: React.FC<LinkTimelineProps> = ({
  label,
  isTop,
  isBottom,
}) => {
  const hasLabel = !!label?.length;
  return (
    <Timestamp visible={hasLabel}>
      {isTop && <TimelineEnd position="top" isShort={!hasLabel} />}
      {isTop && <Timeline position="top" isShort={!hasLabel} />}
      {label ?? <LinkIcon fontSize="small" />}
      <Timeline position="bottom" isShort={!hasLabel} />
      {isBottom && <TimelineEnd position="bottom" isShort={!hasLabel} />}
    </Timestamp>
  );
};

interface Props {
  data: Block;
}

const LinkListBlock: React.FC<Props> = ({ data }) => {
  const links = data.items?.filter(({ type }: Block) => type === 'Link');

  if (!links?.length) return null;

  return (
    <ContentBlock>
      <Typography variant="h2" align="center">
        {data.title}
      </Typography>

      <LinkList>
        {links.map((block: Block, i: number) => {
          const { id, __index, href, text, publishedDateTime } = block;
          const timelineLabel = publishedDateTime
            ? formatDateTime(publishedDateTime)
            : undefined;

          const isOddItem = i % 2 !== 0;

          const linkItem = (
            <a href={href} target="_blank" rel="noreferrer">
              {text}
            </a>
          );

          return (
            <li key={id ?? __index}>
              <div className="left-link">{!isOddItem && linkItem}</div>
              <div className="timeline">
                <LinkTimeline
                  label={timelineLabel}
                  isTop={i === 0}
                  isBottom={i === links.length - 1}
                />
              </div>
              <div className="right-link">{isOddItem && linkItem}</div>
            </li>
          );
        })}
      </LinkList>
    </ContentBlock>
  );
};

export default LinkListBlock;
