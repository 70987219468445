import validator from 'validator';

export const required = (value: any) =>
  value ? undefined : 'errors.validation.required';

export const allowEmpty = (validator: any) => (value: any) =>
  value ? validator(value) : undefined;

export const minLength = (minLength: number) => (value: string) =>
  value && value.length < minLength ? 'errors.validation.minlength' : undefined;

export const isEmail = (value: string) =>
  value && validator.isEmail(value) ? undefined : 'errors.validation.email';

export const isLogin = (value: string) =>
  value && validator.isEmail(value.replace(/^sa:/, ''))
    ? undefined
    : 'errors.validation.email';

const phoneNumberRegexp = /^\+{0,1}\d{7,14}$/;
export const isPhoneNumber = (value: string) =>
  phoneNumberRegexp.test(value.replace(/ /g, ''))
    ? undefined
    : 'errors.validation.phoneNumber';

export const composeValidators =
  (...validators: any[]) =>
  (value: string | number) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

const digitOrSpecialCharRegexp =
  /(?=.*[\d!"#€%&/=§°',\-;:_<>@±£©()[\]{}*+?|^$.\\])/;
export const hasDigitOrSpecialChar = (value: string) =>
  digitOrSpecialCharRegexp.test(value)
    ? undefined
    : 'errors.validation.digitOrSpecialChar';

const digitAndCapsRegexp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]*$/;
export const hasDigitAndCaps = (value: string) =>
  digitAndCapsRegexp.test(value) ? undefined : 'errors.validation.digitAndCaps';

const positiveNumberRegex = /^[0-9]+([.,][0-9]*)?$/;
export const isPositiveNumber = (value: string) =>
  positiveNumberRegex.test(value)
    ? undefined
    : 'errors.validation.mustBeAPositiveNumber';

const negativeNumberRegex = /^((0([.,]0*)?)|((-[0-9]+)([.,][0-9]*)?))$/;
export const isNegativeNumber = (value: string) =>
  negativeNumberRegex.test(value)
    ? undefined
    : 'errors.validation.mustBeANegativeNumber';

const numberRegex = /^[-]?[0-9]+([.,][0-9]*)?$/;
export const isNumber = (value: string) =>
  numberRegex.test(value) ? undefined : 'errors.validation.mustBeANumber';
