import { Link as MaterialLink } from '@material-ui/core';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '../styles';

export const StyledRouterLink = styled(RouterLink)`
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  white-space: nowrap;
`;

interface LinkProps {
  external?: boolean;
  to?: string;
  onClick?: () => void;
}

export const Link: FC<LinkProps> = ({ to, external, onClick, children }) =>
  to ? (
    external ? (
      <MaterialLink target="_blank" href={to}>
        {children}
      </MaterialLink>
    ) : (
      <MaterialLink component={StyledRouterLink} to={to}>
        {children}
      </MaterialLink>
    )
  ) : (
    <MaterialLink component={StyledLink} onClick={onClick}>
      {children}
    </MaterialLink>
  );
