import React from 'react';
import { Block } from '../../stores/models';
import NoPrint from '../NoPrint';
import BulletedTextBlock from './BulletedTextBlock';
import Chart from './Chart';
import DataTable from './DataTable';
import ImageBlock from './ImageBlock';
import VideoBlock from './VideoBlock';
import LinkListBlock from './LinkListBlock';
import NestedBlock from './NestedBlock';
import QuestionnaireBlock from './QuestionnaireBlock';
import SubHeader from './SubHeader';
import TextBlock from './TextBlock';

interface Props {
  data: Block;
  isProduct?: boolean;
  area?: string;
}

const DataBlock: React.FC<Props> = ({ data, isProduct, area }) => {
  const { type } = data;

  const renderBlock = () => {
    const props = { data, isProduct, area };

    switch (type) {
      case 'NestedTextBlock':
      case 'NestedChartBlock':
      case 'NestedDataTableBlock':
        return <NestedBlock {...props} />;
      case 'TextBlock':
        return <TextBlock {...props} />;
      case 'SubHeader':
        return <SubHeader {...props} />;
      case 'Chart':
        return <Chart {...props} />;
      case 'BulletedTextBlock':
        return <BulletedTextBlock {...props} />;
      case 'DataTable':
        return <DataTable {...props} />;
      case 'QuestionnaireBlock':
        return (
          <NoPrint>
            <QuestionnaireBlock {...props} />
          </NoPrint>
        );
      case 'LinkListBlock':
        return (
          <NoPrint>
            <LinkListBlock {...props} />
          </NoPrint>
        );
      case 'ImageBlock':
        return <ImageBlock {...props} />;
      case 'VideoBlock':
        return (
          <NoPrint>
            <VideoBlock {...props} />
          </NoPrint>
        );
      default:
        return null;
    }
  };

  return <div>{renderBlock()}</div>;
};

export default DataBlock;
