import {
  Instance,
  types,
  flow,
  cast,
  getSnapshot,
  getParent,
} from 'mobx-state-tree';
import { ERROR } from '../constants/constants';
import { translate } from '../i18n/translate';
import { api } from '../services/api/ApiClient';
import { NavItemModel } from './models/NavItemModel';

const States = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
];

export const NavigationStore = types
  .model({
    state: types.enumeration('State', States),
    data: types.array(NavItemModel),
  })

  .views(self => ({
    get navigation() {
      const {
        userStore: { userArea },
      } = getParent(self);

      const navigation = [...getSnapshot(self.data)].map(navItem => ({
        ...navItem,
        // Filter pages based on area selection
        pages: navItem.pages.filter(page => page.area?.code === userArea),
      }));

      return navigation;
    },

    getTabs(slug: string) {
      const {
        userStore: { userArea },
      } = getParent(self);

      const navigation = [...getSnapshot(self.data)];
      const navItem = navigation.find(item => item.slug === slug);
      // Filter pages based on area selection
      return navItem?.pages.filter(page => page.area?.code === userArea);
    },
  }))
  .actions(self => {
    const getNavigation = flow(function* (params: Api.Req.GetNavigation = {}) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetNavigation> =
        yield api.getNavigation(params);

      if (response.kind === 'ok') {
        self.data = cast(response.data);
        self.state = 'FETCHED';
      } else {
        notificationStore.setError(translate(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    return {
      getNavigation,
    };
  });

export interface INavigationStore extends Instance<typeof NavigationStore> {}

export default NavigationStore;
