import { api } from '../services/api';
import { EventType } from '../types';

export const triggerEvent = async (type: EventType, uri?: string) => {
  const defaultUri = `${document.location.pathname}${document.location.search}`;
  await api.sendEvent({ type, uri: uri ?? defaultUri });
};

export const triggerPageHitEvent = (uri: string) => {
  if (!uri.includes('/login')) {
    triggerEvent('hit', uri);
  }
};
