import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores';
import Layout from '../../components/Layout/Layout';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ContentBlock from '../../components/Layout/ContentBlock';
import DataBlock from '../../components/DataBlock';
import Button from '../../components/FormControls/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Spinner from '../../components/Spinner';
import NoPrint from '../../components/NoPrint';
import { DownloadButton } from '../../components/DownloadButton';
import Tabs from '../../components/Tabs';
import { useQuery, useQueryParams } from '../../utils/hooks';

export const ProductScreen: FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const { tab } = useQueryParams('tab');

  const { slug } = useParams<{ slug?: string }>();
  const slugRef = useRef<string>();

  const {
    userStore: { userArea },
    productStore: { currentProductState, getProduct, getCategory, product },
  } = useStores();

  const currentProduct = product?.slug === slug ? product : undefined;
  const category = product ? getCategory(product?.categoryId) : undefined;

  const hasSideContentBlocks = !!currentProduct?.data2?.length;

  const blockData =
    tab === 'side' && hasSideContentBlocks
      ? currentProduct?.data2
      : currentProduct?.data;

  const isFetching = currentProductState === 'FETCHING';

  useEffect(() => {
    if (!isFetching && slug && slugRef.current !== slug) {
      slugRef.current = slug;
      getProduct({ slug });
    }
  }, [getProduct, isFetching, slug]);

  return (
    <Layout bgImageUrl={product?.backgroundImage?.url}>
      <NoPrint>
        <ContentBlock transparentWrapper>
          <Button
            onClick={() => {
              const targetTab =
                category?.slug || product
                  ? `?tab=${category?.slug ?? product?.categoryId}`
                  : '';

              history.push(`/products${targetTab}`);
            }}
            label={t('common.goBack')}
            startIcon={<ChevronLeft />}
          />
        </ContentBlock>
      </NoPrint>

      <ContentBlock roundedWrapper>
        {hasSideContentBlocks && (
          <Tabs
            tabs={[
              { slug: 'main', title: t('products.contentCategory.main') },
              { slug: 'side', title: t('products.contentCategory.side') },
            ]}
            onTabChange={(newTab: string) => {
              query.set('tab', newTab);
              history.push({ search: query.toString() });
            }}
            selectedTab={tab ?? 'main'}
            justify="center"
          />
        )}

        <Typography variant="h1" align="center">
          {currentProduct?.name}
        </Typography>
      </ContentBlock>

      {!currentProduct && isFetching && <Spinner verticalPadding="8em" />}

      {blockData?.map(block => (
        <DataBlock
          key={`${block.type}-${block.id}-${block.__index}`}
          data={block}
          isProduct={true}
          area={userArea}
        />
      ))}

      <DownloadButton file={currentProduct?.pdfView} />
    </Layout>
  );
});

export default ProductScreen;
