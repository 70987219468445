import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * Model description here for TypeScript hints.
 */
export const NotificationStoreModel = types
  .model('NotificationStore')
  .props({
    message: types.maybe(types.string),
    error: types.maybe(types.boolean),
    success: types.maybe(types.boolean),
    type: types.maybe(types.string),
  })
  .views(self => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions(self => ({
    setError: (message: string) => {
      self.message = message;
      self.error = true;
      self.type = 'error';
    },
    setSuccess: (message: string) => {
      self.message = message;
      self.success = true;
      self.type = 'success';
    },
    setNotification: (message: string) => {
      self.message = message;
      self.type = 'notification';
    },
    clearNotification: () => {
      self.message = undefined;
      self.error = undefined;
      self.success = undefined;
    },
  }));

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.
 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type NotificationStoreType = Instance<typeof NotificationStoreModel>;
export interface NotificationStore extends NotificationStoreType {}
type NotificationStoreSnapshotType = SnapshotOut<typeof NotificationStoreModel>;
export interface NotificationStoreSnapshot
  extends NotificationStoreSnapshotType {}
