import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import { BREAKPOINTS } from '../styles';
import Button from './FormControls/Button';
import NoPrint from './NoPrint';

const Select = styled.select`
  background-color: ${p => p.theme.colors.white};
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  font-family: Montserrat;
  font-weight: bold;
`;

const Container = styled(Box)<{ justify: 'start' | 'center' | 'end' }>`
  display: flex;
  flex-direction: row;
  justify-content: ${p => p.justify};
  width: 100%;
  gap: 0.5em;
  margin: 1em 0;

  button {
    :not(.selected) {
      border: 1px solid #1f7bb6;
      :hover {
        color: ${p => p.theme.colors.black};
        background-color: ${p => p.theme.colors.grey};
      }
    }
  }
`;

export interface TabItem {
  slug: string;
  title: string;
}

interface Props {
  tabs: TabItem[];
  onTabChange: (tab: string) => void;
  selectedTab: string;
  justify?: 'start' | 'center' | 'end';
}

const Tabs: React.FC<Props> = ({
  tabs,
  onTabChange,
  selectedTab,
  justify = 'start',
}) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.mobile}px)`);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const slug = event.currentTarget.value;
    onTabChange(slug);
  };

  const isSelected = (slug: string) => slug === selectedTab;

  const getClassName = (slug: string) => (isSelected(slug) ? ' selected' : '');

  return (
    <NoPrint>
      <Container justify={justify}>
        {isMobile && (
          <Select value={selectedTab} onChange={handleSelectChange}>
            {tabs.map(({ slug, title }) => (
              <option key={slug} value={slug}>
                {title}
              </option>
            ))}
          </Select>
        )}

        {!isMobile &&
          tabs.map(({ slug, title }) => (
            <Button
              key={slug}
              className={getClassName(slug)}
              onClick={() => onTabChange(slug)}
              label={title}
            />
          ))}
      </Container>
    </NoPrint>
  );
};

export default Tabs;
