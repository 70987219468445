import React, { FC, useState } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Link } from '../../components/Link';
import { styled } from '../../styles';
import { Input, SubmitButton } from '../../components/FormControls';
import { required, composeValidators, minLength } from '../../utils/validators';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';

interface LoginScreenProps {}

const FormContainer = styled(Box)`
  width: 500px;
  padding: 100px 150px;
  margin: auto;
`;

export const PasswordResetScreen: FC<LoginScreenProps> = observer(() => {
  const { t } = useTranslation();
  const { id: userIdParam, token: tokenParam }: { id: string; token: string } =
    useParams();
  const { userStore, notificationStore } = useStores();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string>();

  const onPasswordReset = async (values: { password: string }) => {
    await userStore.passwordReset({
      password: values.password,
      userId: Number(userIdParam),
      token: tokenParam,
    });
    if (notificationStore.error) {
      setError('Salasanan resetointi epäonnistui');
      setSuccess('');
    }
    if (notificationStore.success) {
      setSuccess('Salasanana resetointi onnistui');
      setError('');
    }
  };

  const FIELDS = [
    {
      id: 'password',
      type: 'password',
      label: 'user.password',
      validate: composeValidators(required, minLength(1)),
    },
    {
      id: 'confirm',
      type: 'password',
      label: 'user.passwordConfirm',
      validate: composeValidators(required, minLength(1)),
    },
  ];

  return (
    <Form
      onSubmit={onPasswordReset}
      validate={(values: { password: string; confirm: string }) => {
        const errors: { confirm: string | undefined } = { confirm: undefined };
        if (values.confirm !== values.password) {
          errors.confirm = t('errors.validation.passwordConfirm');
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContainer>
              <Grid container spacing={8} justifyContent="center">
                <Grid item xs={12}>
                  <img
                    style={{ width: '100%' }}
                    src="/Forecon-logo-txt.svg"
                    alt="Forecon logo"
                  />
                </Grid>
                <Grid item>
                  <Typography color="primary" variant="h2">
                    {t('login.resetPasswordHeader')}
                  </Typography>
                </Grid>
              </Grid>
              {FIELDS.map(({ id, type, label, validate }) => (
                <Grid container spacing={8} justifyContent="center" key={id}>
                  <Grid item xs={12}>
                    <Input
                      validate={validate}
                      label={label}
                      type={type}
                      id={id}
                      name={id}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={8} justifyContent="center">
                <Grid item>
                  <SubmitButton
                    label="login.resetPassword"
                    loading={submitting}
                    disabled={pristine || hasValidationErrors}
                  />
                </Grid>
              </Grid>
              {error && (
                <Grid container spacing={8} justifyContent="center">
                  <Grid item>
                    <Typography color="primary" variant="h2">
                      {error}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {success && (
                <Grid container spacing={8} justifyContent="center">
                  <Grid item>
                    <Typography color="primary" variant="h2">
                      {success}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={8} justifyContent="center">
                <Grid item>
                  <Typography>
                    <Link to="/login">{t('login.loginLink')}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </FormContainer>
          </form>
        );
      }}
    />
  );
});
