import React, { useEffect } from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import UserIcon from '@material-ui/icons/Person';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import { User } from '../../stores/models';
import { pathForRoute } from '../../constants/routes';
import { useHistory } from 'react-router-dom';

const Container = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${p => p.theme.breakpoints.tablet} {
    margin-bottom: 0;
  }
`;

const UserButton = styled.button`
  display: flex;
  align-items: center;
  margin: 8px;
  background-color: transparent;
  color: ${p => p.theme.colors.white};
  border: none;
  cursor: pointer;

  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;

  text-decoration: underline;
  :hover {
    text-decoration: none;
  }

  svg {
    color: ${p => p.theme.colors.white};
  }

  .user-menu-button__user-name {
    margin-left: 12px;
    @media ${p => p.theme.breakpoints.tablet} {
      display: none;
    }
  }
`;

const UserMenu: React.FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const {
    userStore: { user, token, logout, getMe },
  } = useStores();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!user && token?.length) {
      // Check if token is present in the store, while the user for some reason is not.
      // (Probably the page was refreshed). Fetch user's data from the API:
      getMe();
    }
  }, [getMe, token, user]);

  const onLogoutClick = () => {
    logout();
    history.push('/login');
  };

  const onSettingsClick = () => {
    const path = pathForRoute('userSettings');
    history.push(path ?? '/user/settings');
  };

  const getUserName = (user?: User) => {
    const userName = user?.name ?? '';
    const company = user?.company?.name ? `, ${user.company.name}` : '';
    return `${userName}${company}`;
  };

  const menuItems = [
    { label: t('userMenu.settings'), onClick: onSettingsClick },
    { label: t('userMenu.logout'), onClick: onLogoutClick },
  ];

  return (
    <Container>
      <UserButton
        id="user-menu-button"
        onClick={handleOpenMenu}
        aria-controls="user-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <UserIcon />
        <span className="user-menu-button__user-name">{getUserName(user)}</span>
      </UserButton>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        {menuItems.map(({ label, onClick }) => (
          <MenuItem key={label} onClick={onClick}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
});

export default UserMenu;
