import React from 'react';
import styled from 'styled-components';
import { Block } from '../../stores/models';
import { round } from '../../utils/math';
import GaugeChart, { Scale } from '../Charts/GaugeChart';
import ContentBlock from '../Layout/ContentBlock';
import { ExportData } from '../ExportData';

const Container = styled.div`
  max-width: 86vw;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2em;

  @media ${p => p.theme.breakpoints.mobile} {
    padding: 0;
  }

  .bold-last-row {
    tr:last-child {
      font-weight: bold;
    }
  }
`;

const Table = styled.table`
  margin-left: auto;
  margin-right: auto;
  td,
  th {
    :not(:first-child) {
      width: 65px;
    }
  }
  td {
    padding: 0.5em;
    :first-child {
      font-weight: bold;
    }
  }
  tbody {
    tr {
      :nth-child(odd) {
        background-color: ${p => p.theme.colors.grey};
      }
      td {
        :not(:first-child) {
          text-align: center;
        }
      }
    }
  }
`;

const GaugeChartContainer = styled.div`
  float: right;
  width: 250px;
  margin-top: -5px;
  margin-left: -30px;
  margin-right: -30px;

  @media ${p => p.theme.breakpoints.tablet} {
    float: none;
    width: 50%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    width: 100%;
  }
`;

interface Props {
  data: Block;
  isProduct?: boolean;
  area?: string;
}

const DataTable: React.FC<Props> = ({ data, isProduct, area }) => {
  const { csvData, csvDataType, showGauge, gaugeValue } = data;
  const tableData = csvData ?? [];

  const [headerRow, ...dataRows] = tableData;

  const gaugeScale: Scale = [-1, 1];

  const validGaugeValue = (value?: number | null) => {
    if (!value) return false;
    return !isNaN(value);
  };

  const formatValue = (value: ICsvCell | null) => {
    if (value === null) return;

    const numValue =
      typeof value === 'string'
        ? Number(value.replace(',', '.'))
        : Number(value);

    if (!isNaN(numValue) && value !== '') {
      if (csvDataType === 'numbers') return `${numValue}`;
      const percentValue = round(numValue * 100);
      const nonBreakableSpace = `\xa0`;
      return `${percentValue}${nonBreakableSpace}%`;
    }
    return value;
  };

  return (
    <ContentBlock title={data.title}>
      <Container>
        {showGauge && validGaugeValue(gaugeValue) && (
          <GaugeChartContainer>
            <GaugeChart value={gaugeValue} valueScale={gaugeScale} zoom={2} />
          </GaugeChartContainer>
        )}
        <Table
          className={'' + (isProduct && area === 'se' ? 'bold-last-row' : '')}
        >
          <thead>
            <tr>
              {headerRow.map((label: string, i: number) => (
                <th key={`${i}-${label}`}>{label}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {dataRows.map((row: ICsvRow, i: number) => (
              <tr key={`row-${i}`}>
                {row.map((value: ICsvCell, i: number) => (
                  <td key={`${i}-${value}`}>{formatValue(value)}</td>
                ))}
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td style={{ padding: '0.5em 0' }}>
                <ExportData
                  data={tableData}
                  title={data.title}
                  eventType="data_table_upload"
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </Container>
    </ContentBlock>
  );
};

export default DataTable;
