import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { AreaModel } from './AreaModel';
import { BlockModel } from './BlockModel';
import { MediaModel } from './MediaModel';

export const ProductModel = types.model({
  id: types.maybe(types.identifierNumber),
  name: types.string,
  slug: types.maybe(types.string),
  categoryId: types.number,
  areaId: types.maybe(types.number),
  area: types.maybeNull(AreaModel),
  priceExpectation: types.maybeNull(types.string),
  forecast: types.maybeNull(types.number),
  index: types.maybeNull(types.number),
  backgroundImage: types.maybeNull(MediaModel),
  pdfView: types.maybeNull(MediaModel),
  createdDateTime: types.maybe(types.string),
  updatedDateTime: types.maybe(types.string),
  data: types.maybeNull(types.array(BlockModel)),
  data2: types.maybeNull(types.array(BlockModel)),
  dataUpdatedDateTime: types.string,
});

export interface IProductModel extends Instance<typeof ProductModel> {}
export interface Product extends SnapshotOut<typeof ProductModel> {}
export interface ProductIn extends SnapshotIn<typeof ProductModel> {}

export const CategoryModel = types.model({
  id: types.maybe(types.identifierNumber),
  name: types.string,
  slug: types.maybeNull(types.string),
  products: types.maybeNull(types.array(ProductModel)),
});

export interface ICategoryModel extends Instance<typeof CategoryModel> {}
export interface Category extends SnapshotOut<typeof CategoryModel> {}
export interface CategoryIn extends SnapshotIn<typeof CategoryModel> {}

export const ProductInfoTextModel = types.model({
  id: types.maybe(types.identifierNumber),
  productInfoId: types.number,
  content: types.string,
  order: types.number,
});

export interface IProductInfoModel extends Instance<typeof ProductInfoModel> {}
export interface ProductInfo extends SnapshotOut<typeof ProductInfoModel> {}
export interface ProductInfoIn extends SnapshotIn<typeof ProductInfoModel> {}

export const ProductInfoChartModel = types.model({
  id: types.maybe(types.identifierNumber),
  productInfoId: types.number,
});

export interface IProductInfoModel extends Instance<typeof ProductInfoModel> {}
export interface ProductInfo extends SnapshotOut<typeof ProductInfoModel> {}
export interface ProductInfoIn extends SnapshotIn<typeof ProductInfoModel> {}

export const ProductInfoPollModel = types.model({
  id: types.maybe(types.identifierNumber),
  productInfoId: types.number,
  order: types.number,
});

export interface IProductInfoModel extends Instance<typeof ProductInfoModel> {}
export interface ProductInfo extends SnapshotOut<typeof ProductInfoModel> {}
export interface ProductInfoIn extends SnapshotIn<typeof ProductInfoModel> {}

export const ProductInfoLinksModel = types.model({
  id: types.maybe(types.identifierNumber),
  productInfoId: types.number,
  url: types.string,
  title: types.string,
  order: types.number,
});

export interface IProductInfoModel extends Instance<typeof ProductInfoModel> {}
export interface ProductInfo extends SnapshotOut<typeof ProductInfoModel> {}
export interface ProductInfoIn extends SnapshotIn<typeof ProductInfoModel> {}

export const ProductInfoModel = types.model({
  id: types.maybe(types.identifierNumber),
  productId: types.number,
  title: types.string,
  order: types.number,
  type: types.string,
  chartType: types.maybe(types.string),
  text: types.maybe(types.array(ProductInfoTextModel)),
  chart: types.maybe(types.array(ProductInfoChartModel)),
  links: types.maybe(types.array(ProductInfoLinksModel)),
  poll: types.maybe(types.array(ProductInfoPollModel)),
});

export interface IProductInfoModel extends Instance<typeof ProductInfoModel> {}
export interface ProductInfo extends SnapshotOut<typeof ProductInfoModel> {}
export interface ProductInfoIn extends SnapshotIn<typeof ProductInfoModel> {}
