import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useStores } from '../stores/index';
import { triggerEvent } from '../utils/events';
import { Media } from '../stores/models';

const Button = styled(MuiButton)`
  position: fixed;
  bottom: 60px;
  left: 110px;

  @media ${p => p.theme.breakpoints.tablet} {
    position: relative;
    margin: 20px;
    bottom: 0px;
    left: 0px;
    align-self: flex-start;
  }

  @media print {
    display: none;
  }
`;

interface DownloadButtonProps {
  file?: Media | null;
}

export const DownloadButton: React.FC<DownloadButtonProps> = observer(
  ({ file }) => {
    const { t } = useTranslation();

    const {
      userStore: { isLoggedIn },
    } = useStores();

    const handleDownload = () => {
      triggerEvent('page_pdf_upload');
      if (file) {
        const link = document.createElement('a');
        link.download = file.name;
        link.href = file.url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.print();
      }
    };

    if (!isLoggedIn) return null;

    return (
      <Button
        size="large"
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleDownload}
      >
        {t('common.downloadPDF')}
        <DownloadIcon />
      </Button>
    );
  },
);

export default DownloadButton;
