import { DateTime } from 'luxon';

const LOCALE = 'fi-FI';
const TIMEZONE = 'Europe/Helsinki';

interface DateOptions {
  format?: string;
  timezone?: string;
  locale?: string;
}

export const localizedDate = (
  isoDate: string,
  { timezone = TIMEZONE, locale = LOCALE }: DateOptions = {},
) => {
  return DateTime.fromISO(isoDate).setZone(timezone).setLocale(locale);
};

export const today = () => {
  const nowISO = DateTime.now().toISO();
  return localizedDate(nowISO);
};

export const formatDateTime = (isoDate: string, options: DateOptions = {}) => {
  const { format = 'D' } = options;
  return localizedDate(isoDate, options).toFormat(format);
};
