import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Header } from '.';
import { Footer } from '.';
import NoPrint from '../NoPrint';

const LayoutContainer = styled(Box)`
  display: flex;
  align-items: stretch;
  @media print {
    display: block;
  }
`;

const BgImageContainer = styled.div<{ imageUrl: string }>`
  position: absolute;
  width: 100%;
  height: 500px;
  background: linear-gradient(rgba(34, 48, 78, 0.6), rgba(34, 48, 78, 0.6)),
    url(${p => p.imageUrl}) 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

  @media ${p => p.theme.breakpoints.tablet} {
    height: 350px;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    height: 190px;
  }
`;

const ScreenContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media print {
    display: block;
  }
`;

interface Props {
  bgImageUrl?: string;
  showLanguageSelect?: boolean;
}

const Layout: FC<Props> = ({ children, bgImageUrl, showLanguageSelect }) => {
  return (
    <LayoutContainer>
      <NoPrint>
        <BgImageContainer imageUrl={bgImageUrl ?? '/default-bg-image.jpg'} />
      </NoPrint>
      <ScreenContainer>
        <Header showLanguageSelect={showLanguageSelect} />

        {children}

        <Footer />
      </ScreenContainer>
    </LayoutContainer>
  );
};

export default Layout;
