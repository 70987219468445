import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    fontSize: 12,
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.8)',
  },
}))(MuiTooltip);

interface Props {
  title: string;
  placement?: 'top' | 'bottom';
}

const Tooltip: React.FC<Props> = ({ title, placement = 'top', children }) => {
  return (
    <StyledTooltip title={title} placement={placement} arrow>
      <span>{children}</span>
    </StyledTooltip>
  );
};

export default Tooltip;
