import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { styled } from '../../styles';
import { Wrapper } from './Wrapper';

const COMPANY = 'Forecon Ltd';
const CONTACTS = [
  'Visiokatu 4',
  '33720 TAMPERE',
  'info@forecon.fi',
  '+358 50 506 1766',
];

const FooterBox = styled(Box)`
  color: #fff;
  min-height: ${props => props.theme.constants.headerHeight};
  background-color: rgba(34, 48, 78);
  margin-top: auto;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media ${p => p.theme.breakpoints.mobile} {
    > div {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  @media print {
    display: none;
  }
`;

const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  @media ${p => p.theme.breakpoints.mobile} {
    margin-left: 0;
    margin-bottom: 40px;
    text-align: center;
  }
`;

const FooterLogoContainer = styled(Box)`
  margin-right: 30px;
  display: flex;
  img {
    height: 70px;
  }
  @media ${p => p.theme.breakpoints.mobile} {
    margin: 10px 0;
    img {
      height: 50px;
    }
  }
`;

export const Footer: FC = observer(() => {
  return (
    <FooterBox>
      <Wrapper transparentBg>
        <FooterLogoContainer>
          <img src="/Forecon_PRIX_ikoni_web.svg" alt="Forecon" />
        </FooterLogoContainer>

        <InfoContainer>
          <Typography variant="subtitle1">{COMPANY}</Typography>
          {CONTACTS.map(label => (
            <Typography key={label} variant="subtitle2">
              {label}
            </Typography>
          ))}
        </InfoContainer>
      </Wrapper>
    </FooterBox>
  );
});
