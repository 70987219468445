import React from 'react';
import { Typography } from '@material-ui/core';
import { Block } from '../../stores/models';
import DataBlock from '.';
import ContentBlock from '../Layout/ContentBlock';

interface Props {
  data: Block;
  isProduct?: boolean;
  area?: string;
}

const NestedBlock: React.FC<Props> = ({ data, isProduct, area }) => {
  return (
    <div>
      {data.title && (
        <ContentBlock noMargin>
          <Typography variant="h2" align="center">
            {data.title}
          </Typography>
        </ContentBlock>
      )}

      {data.items?.map((block: Block) => (
        <DataBlock
          key={`${block.type}-${block.id}-${block.__index}`}
          data={block}
          isProduct={isProduct}
          area={area}
        />
      ))}
    </div>
  );
};

export default NestedBlock;
