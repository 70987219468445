import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { styled } from '../../styles';
import { RootStore, useStores } from '../../stores/index';
import Navigation from './Navigation';
import UserMenu from './UserMenu';
import { Wrapper } from './Wrapper';
import LanguageMenu from './LanguageMenu';
import AreaMenu from './AreaMenu';

const HeaderBox = styled(Box)`
  color: #fff;
  background-color: rgba(34, 48, 78, 0.8);
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${props => props.theme.constants.headerHeight};
    padding: 0;
  }

  @media ${p => p.theme.breakpoints.tablet} {
    > div {
      height: ${props => props.theme.constants.headerHeightMobile};
      padding: 0;
    }
  }

  @media print {
    display: none;
  }
`;

const LogoContainer = styled(Box)`
  display: flex;
  img {
    height: 70px;
    width: auto;
  }
  @media ${p => p.theme.breakpoints.tablet} {
    img {
      height: 30px;
    }
  }
`;

const NavContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${p => p.theme.breakpoints.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

const MenuContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;
`;

interface Props {
  showLanguageSelect?: boolean;
}

export const Header: FC<Props> = observer(({ showLanguageSelect = true }) => {
  const {
    userStore: { isLoggedIn },
  } = useStores() as RootStore;

  return (
    <HeaderBox pl={4} pr={4}>
      <Wrapper transparentBg>
        <LogoContainer>
          <img src="/Forecon_PRIX_clogo_wtext.png" alt="Forecon" />
        </LogoContainer>
        {isLoggedIn ? (
          <NavContainer>
            <MenuContainer>
              <AreaMenu />
              {showLanguageSelect && <LanguageMenu />}
              <UserMenu />
            </MenuContainer>
            <Navigation />
          </NavContainer>
        ) : (
          <MenuContainer>
            {showLanguageSelect && <LanguageMenu />}
          </MenuContainer>
        )}
      </Wrapper>
    </HeaderBox>
  );
});
