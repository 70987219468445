import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import { MUI_THEME } from '../styles';
import { QuestionType } from '../types/Feedback';
interface Props extends Omit<ReactSliderProps, 'onChange'> {
  questionType?: QuestionType;
  onChange: (value: number | null) => void;
  isInputVisible?: boolean;
  sliderInputDecoration?: string;
  scaleMinLabel?: string;
  scaleMaxLabel?: string;
}

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      'max-width': '4rem',
      'text-align': 'center',
      '-moz-appearance': 'textfield',
      'font-size': '120%',
      'padding': '0.5rem',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  track: {
    backgroundColor: MUI_THEME.palette.grey[500],
    height: 2,
    flex: 1,
    left: '8px !important',
    right: '24px !important',
  },
  markStroke: {
    marginBottom: 4,
    width: 24,
    marginLeft: -4,
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    cursor: 'grab',
    '&.end-mark:after': {
      height: 18,
      marginTop: -8,
    },
    '&:after': {
      content: "''",
      display: 'flex',
      width: 1,
      marginTop: -3,
      height: 9,
      position: 'absolute',
      backgroundColor: MUI_THEME.palette.grey[700],
    },
  },
  activeThumb: {
    backgroundColor: MUI_THEME.palette.primary.light,
    boxShadow: '0 0 0 10px rgba(63, 81, 181, 0.16)',
  },
  thumb: {
    marginTop: -15,
    marginLeft: -8,
    height: 33,
    width: 33,
    borderRadius: 33,
    backgroundColor: MUI_THEME.palette.primary.main,
    cursor: 'grab',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.9rem',
    alignItems: 'center',
    transition: 'all 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  slider: {
    display: 'flex',
    padding: 0,
  },
  inputDecoration: {
    position: 'absolute',
    right: -26,
    top: 7,
    fontSize: '120%',
    fontWeight: 600,
  }
});

const Slider: React.FC<Props> = ({
  max = 1,
  min = 5,
  defaultValue,
  disabled,
  marks,
  onChange,
  step = 1,
  questionType,
  isInputVisible,
  sliderInputDecoration,
  scaleMinLabel,
  scaleMaxLabel,
}) => {
  const classes = useStyles();
  const prevValue = useRef(defaultValue ?? 3);
  const { t } = useTranslation();

  const [value, setValue] = useState<number | null>(
    (defaultValue as number) ?? min ?? max ?? 3,
  );

  // Changes the external value when the internal value changes
  useEffect(() => {
    if (onChange) {
      if (prevValue.current !== value && typeof value === 'number') {
        onChange(value);
        prevValue.current = value;
      }
    }
  }, [value, onChange, prevValue]);

  // Changes the internal value when slider is moved
  const handleSliderChange = (value: number) => {
    setValue(value as number);
  };

  // Changes the internal value when input is changed
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value === '' ? null : Number(event.target.value));
  };

  const customMarks = useMemo(() => {
    let customMarks = [];
    let adjustedStep = step < 1 ? 1 : step;
    for (let i = min; i <= max; i += adjustedStep) {
      customMarks.push(i);
    }

    const maxMarkers = 11;
    const availableMarkers = customMarks.length;

    if (availableMarkers > maxMarkers) {
      const divisor = Math.ceil(availableMarkers / maxMarkers);
      customMarks = customMarks.filter((mark, index) => index % divisor === 0);
    }

    return customMarks;
  }, [min, max, step]);


  const minMaxKeys = [customMarks[0], customMarks[customMarks.length - 1]];

  return (
    <Box display="flex" alignItems="center">
      <Box flex="1">
        {isInputVisible && (
          <Box marginTop={2} position="relative" maxWidth="5rem" marginX="auto">
            <TextField
              value={value}
              className={classes.input}
              variant="outlined"
              onChange={handleInputChange}
              inputProps={{
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
            {sliderInputDecoration && (
              <Typography className={classes.inputDecoration}>{sliderInputDecoration}</Typography>
            )}
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Box flex="1" marginBottom={10}>
            {questionType && (
              <Box
                className="questions__slider-labels--container"
                paddingBottom={10}
              >
                <Typography variant="body2">
                  {t(`feedback.${questionType}.start`)}
                  {scaleMinLabel}
                </Typography>
                <Typography variant="body2">
                  {t(`feedback.${questionType}.end`)}
                  {scaleMaxLabel}
                </Typography>
              </Box>
            )}
            <Box marginLeft="1rem">
              <ReactSlider
                min={min}
                max={max}
                defaultValue={defaultValue as number}
                step={step ?? 1}
                value={typeof value === 'number' ? value : 0}
                marks={marks ? customMarks : undefined}
                onChange={handleSliderChange}
                disabled={disabled}
                className={classes.slider}
                thumbClassName={classes.thumb}
                thumbActiveClassName={classes.activeThumb}
                trackClassName={classes.track}
                markClassName={classes.markStroke}
                renderThumb={props => (
                  <Box {...props}>
                    {questionType !== 'slider.no-yes' && value}
                  </Box>
                )}
                renderMark={props => {
                  let className = props.className;
                  if (minMaxKeys.includes(props.key as number)) {
                    className += ' end-mark';
                  }
                  return <Box {...props} className={className} />;
                }}
                renderTrack={props => (
                  <Box {...props}>
                    {!!max && !!min && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        paddingTop={6}
                      >
                        <Typography variant="body2">
                          {`${min} ${sliderInputDecoration ?? ''}`}
                        </Typography>
                        <Typography variant="body2">
                          {`${max} ${sliderInputDecoration ?? ''}`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Slider;
