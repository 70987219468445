import React from 'react';
import {
  DateTimePicker,
  TimePicker,
  DatePicker as MaterialDatePicker,
} from '@material-ui/pickers';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { styled } from '../../styles';

interface DatePickerProps {
  id: string;
  name: string;
  label: string;
  okLabel?: string;
  cancelLabel?: string;
  required?: boolean;
  validate?: any;
  type: 'datetime' | 'time' | 'date';
}

const StyledDateTimePicker = styled(DateTimePicker)`
  width: 100%;
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
`;

const StyledDatePicker = styled(MaterialDatePicker)`
  width: 100%;
`;

export const DatePicker: (
  props: DatePickerProps,
) => React.ReactElement<DatePickerProps> = ({
  id,
  name,
  label,
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  required,
  validate,
  type,
}) => {
  const { t } = useTranslation();

  const render = (props: any) => {
    const {
      input: { value, onChange },
      meta,
    } = props;

    switch (type) {
      case 'datetime':
        return (
          <StyledDateTimePicker
            id={id}
            label={t(label)}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
            value={value === '' ? null : value}
            onChange={onChange}
            ampm={false}
            minutesStep={15}
            inputVariant="outlined"
            error={!!meta.error}
            helperText={meta.error ? meta.error : null}
          />
        );
      case 'time':
        return (
          <StyledTimePicker
            id={id}
            label={t(label)}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
            value={value === '' ? null : value}
            onChange={onChange}
            ampm={false}
            minutesStep={15}
            inputVariant="outlined"
            error={!!meta.error}
            helperText={meta.error ? meta.error : null}
          />
        );
      case 'date':
        return (
          <StyledDatePicker
            id={id}
            label={t(label)}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
            value={value === '' ? null : value}
            onChange={onChange}
            inputVariant="outlined"
            error={!!meta.error}
            format="DD.MM.YYYY"
            helperText={meta.error ? meta.error : null}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Field
      name={name}
      validate={validate}
      required={required}
      render={render}
    />
  );
};
