import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const PersonModel = types.model({
  id: types.maybeNull(types.string), // uuid
  email: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
});

export interface IPersonModel extends Instance<typeof PersonModel> {}
export interface Person extends SnapshotOut<typeof PersonModel> {}
export interface PersonIn extends SnapshotIn<typeof PersonModel> {}
