import React, { RefObject } from 'react';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { MenuContainer } from './Layout/StyledMenu';
import Button from './FormControls/Button';
import html2canvas from 'html2canvas';
import { THEME } from '../styles';
import { DateTime } from 'luxon';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import NoPrint from './NoPrint';
import { EventType } from '../types';
import { triggerEvent } from '../utils/events';

const CHART_IMAGE_FORMAT = 'png';

interface Props {
  data: any[];
  title?: string;
  chartRef?: RefObject<HTMLDivElement>;
  imageDownload?: boolean;
  eventType: EventType;
}

export const ExportData: React.FC<Props> = ({
  data,
  title,
  chartRef,
  imageDownload = false,
  eventType,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [downloading, setDownloading] = React.useState(false);
  const { t } = useTranslation();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getFilename = (title: string | undefined, type: string) => {
    const timestamp = DateTime.now().toFormat('yyyyLLdd-HHmm');
    return title?.length
      ? `${title}-${timestamp}.${type}`
      : `${timestamp}.${type}`;
  };

  const handleTriggerEvent = (target: 'img' | 'excel' | 'csv') => {
    const isImg = target === 'img';
    const isXls = target === 'excel';
    const isCsv = target === 'csv';
    switch (eventType) {
      case 'chart_upload':
        const chartEvent = isImg
          ? 'chart_upload_img'
          : isXls
          ? 'chart_upload_excel'
          : isCsv
          ? 'chart_upload_csv'
          : undefined;
        if (chartEvent) return triggerEvent(chartEvent);
        else return triggerEvent(eventType);

      case 'data_table_upload':
        const dataTableEvent = isImg
          ? 'data_table_upload_img'
          : isXls
          ? 'data_table_upload_excel'
          : isCsv
          ? 'data_table_upload_csv'
          : undefined;
        if (dataTableEvent) return triggerEvent(dataTableEvent);
        else return triggerEvent(eventType);

      default:
        return triggerEvent(eventType);
    }
  };

  const handleImageDownload = async () => {
    if (chartRef?.current) {
      handleTriggerEvent('img');
      setDownloading(true);
      const canvas = await html2canvas(chartRef.current, {
        backgroundColor: THEME.colors.primary,
      });

      const image = canvas.toDataURL(`image/${CHART_IMAGE_FORMAT}`);

      const link = document.createElement('a');
      link.download = getFilename(title, 'png');
      link.href = image;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloading(false);
    }
  };

  const handleXslxDownload = () => {
    handleTriggerEvent('excel');
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(workbook, getFilename(title, 'xlsx'));
  };

  return (
    <NoPrint>
      <MenuContainer style={{ margin: '0', justifyContent: 'flex-start' }}>
        <Button
          onClick={handleOpenMenu}
          type="button"
          label={t('charts.download')}
          size="small"
          variant="outlined"
        />
        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {imageDownload && (
            <MenuItem onClick={handleImageDownload} value="downloadImage">
              {t('charts.downloadImage')}
              {downloading && <CircularProgress size={16} color="inherit" />}
            </MenuItem>
          )}
          <MenuItem value="exportCsv">
            <CSVLink
              data={data}
              style={{ textDecoration: 'none', color: 'black' }}
              filename={getFilename(title, 'csv')}
              onClick={() => handleTriggerEvent('csv')}
            >
              {t('charts.exportCsv')}
            </CSVLink>
          </MenuItem>
          <MenuItem onClick={handleXslxDownload} value="exportExcel">
            {t('charts.exportExcel')}
          </MenuItem>
        </Menu>
      </MenuContainer>
    </NoPrint>
  );
};
