import React from 'react';
import { Typography } from '@material-ui/core';
import { Block } from '../../stores/models';
import ContentBlock from '../Layout/ContentBlock';

interface Props {
  data: Block;
}

const SubHeader: React.FC<Props> = ({ data }) => {
  return (
    <ContentBlock>
      <Typography variant="h3" align="center">
        {data.value}
      </Typography>
    </ContentBlock>
  );
};

export default SubHeader;
