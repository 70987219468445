import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import DataBlock from '../../components/DataBlock';
import DownloadButton from '../../components/DownloadButton';
import ContentBlock from '../../components/Layout/ContentBlock';
import Layout from '../../components/Layout/Layout';
import NoPrint from '../../components/NoPrint';
import Spinner from '../../components/Spinner';
import Tabs from '../../components/Tabs';
import { useStores } from '../../stores';
import { useQuery, useQueryParams } from '../../utils/hooks';
import { useTranslation } from 'react-i18next';

export const ContentPageScreen: React.FC = observer(() => {
  const { t } = useTranslation();
  const { navigationpath, tab } = useParams<{ [key: string]: string }>();
  const history = useHistory();
  const query = useQuery();
  const { tab: pageCategoryTab } = useQueryParams('tab');

  const slugRef = useRef<string>();
  const {
    navigationStore: { getTabs },
    contentPageStore: { state, fetchPage, getPage },
  } = useStores();

  const isFetching = state === 'FETCHING';

  const tabs = getTabs(navigationpath);

  const hasManyTabs = tabs && tabs.length > 1;

  // If tab is not defined, use first tab from navigation
  // If navigation does not have tabs, fetch the page from API
  // using navigationpath as slug
  const slug = tab ?? tabs?.[0].slug ?? navigationpath;

  // Get page from store
  const page = getPage(slug);

  const hasSideContentBlocks = !!page?.data2?.length;

  const blockData =
    pageCategoryTab === 'side' && hasSideContentBlocks
      ? page?.data2
      : page?.data;

  // Fetch page from API (if needed)
  useEffect(() => {
    if (!page && slugRef.current !== slug && !isFetching) {
      // Page was not already found from the store and slug was changed
      slugRef.current = slug;
      fetchPage({ slug });
    }
  }, [state, fetchPage, page, slug, isFetching]);

  useEffect(() => {
    if (page?.enableAnalytics) {
      const script = document.createElement('script');
      script.src = '//stats.docu.info/docu-snippet.js';
      script.async = true;
      script.defer = true;
      script.id = 'docu-snippet';
      script.setAttribute('data-site-id', '6');
      script.setAttribute('data-domain-id', '775');
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [page]);

  const handleTabChange = (slug: string) => {
    let url = `${navigationpath}/${slug}`;
    if (!navigationpath) {
      url = slug;
    }
    history.push(`/${url}`);
  };

  const getTitle = () => {
    if (state === 'ERROR') return '404';
    return page?.title ?? '';
  };

  return (
    <Layout bgImageUrl={page?.backgroundImage?.url}>
      {hasManyTabs && (
        <NoPrint>
          <ContentBlock noMargin transparentWrapper>
            <Tabs
              tabs={tabs}
              onTabChange={handleTabChange}
              selectedTab={slug}
            />
          </ContentBlock>
        </NoPrint>
      )}

      <ContentBlock roundedWrapper pullDown={!hasManyTabs}>
        {hasSideContentBlocks && (
          <Tabs
            tabs={[
              { slug: 'main', title: t('contentPages.contentCategory.main') },
              { slug: 'side', title: t('contentPages.contentCategory.side') },
            ]}
            onTabChange={(newTab: string) => {
              query.set('tab', newTab);
              history.push({ search: query.toString() });
            }}
            selectedTab={pageCategoryTab ?? 'main'}
            justify="center"
          />
        )}

        <Typography variant="h1" align="center">
          {getTitle()}
        </Typography>
      </ContentBlock>

      {!page && isFetching && <Spinner verticalPadding="8em" />}

      {blockData?.map(block => (
        <DataBlock
          key={`${block.type}-${block.id}-${block.__index}`}
          data={block}
        />
      ))}

      <DownloadButton file={page?.pdfView} />
    </Layout>
  );
});

export default ContentPageScreen;
