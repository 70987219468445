import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { IconButton, useMediaQuery, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { BREAKPOINTS } from '../../styles';
import { useStores } from '../../stores';
import { NavItem as StoreNavItem } from '../../stores/models/NavItemModel';

const MobileContainer = styled.div`
  svg {
    color: ${p => p.theme.colors.white};
  }
`;

const StyledMenuLink = styled(NavLink)`
  text-decoration: none;
  color: ${(props: any) => props.theme.colors.black};
`;

const StyledLink = styled(NavLink)`
  display: inline-block;
  background-color: #667189;
  margin: 0 0 0 0.5em;
  text-decoration: none;
  color: ${(props: any) => props.theme.colors.white};
  padding: 0.5em 1em;
  border-radius: 5px;
`;

interface NavItem {
  path: string;
  label: string;
}

interface NavigationProps {
  navItems: NavItem[];
}

const MobileNavigation: React.FC<NavigationProps> = ({ navItems }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MobileContainer>
      <IconButton
        id="navigation-button"
        onClick={handleOpenMenu}
        aria-controls="navigation-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'navigation-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        {navItems.map(({ path, label }) => (
          <MenuItem key={path} onClick={handleClose}>
            <StyledMenuLink to={path} exact strict>
              {label}
            </StyledMenuLink>
          </MenuItem>
        ))}
      </Menu>
    </MobileContainer>
  );
};

const DesktopNavigation: React.FC<NavigationProps> = ({ navItems }) => (
  <>
    {navItems.map(({ path, label }) => (
      <StyledLink
        key={path}
        to={path}
        exact
        strict
        activeStyle={{
          backgroundColor: '#1F7BB6',
        }}
      >
        {label}
      </StyledLink>
    ))}
  </>
);

const Navigation: React.FC = observer(() => {
  const { t } = useTranslation();

  const {
    navigationStore: { state, getNavigation, navigation },
  } = useStores();

  useEffect(() => {
    if (state === 'NOT_FETCHED') {
      getNavigation();
    }
  }, [getNavigation, state]);

  const isTablet = useMediaQuery(`(max-width: ${BREAKPOINTS.tablet}px)`);

  const composeDynamicNavItem = ({ name, slug, pages }: StoreNavItem) => {
    return {
      path: `/${slug}/${pages[0].slug}`,
      label: name,
    };
  };

  const navItems = [
    { path: '/products', label: t('navigation.products') },
    ...navigation
      .filter(navItem => !!navItem.pages.length)
      .map(composeDynamicNavItem),
  ];

  return (
    <div>
      {isTablet && <MobileNavigation navItems={navItems} />}
      {!isTablet && <DesktopNavigation navItems={navItems} />}
    </div>
  );
});

export default Navigation;
