export const round = (value: number, precision = 10) => {
  // 1000 = 3 decimals / 100 = 2 decimals / etc.
  return Math.round((value + Number.EPSILON) * precision) / precision;
};

export const normalize = (
  value: number,
  min: number,
  max: number,
  scale: '0to1' | '-1to1' = '0to1',
) => {
  const normalizedValue = (value - min) / (max - min);
  if (scale === '-1to1') {
    return 2 * normalizedValue - 1;
  }
  return normalizedValue;
};
