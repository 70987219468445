import {
  Instance,
  types,
  flow,
  cast,
  getSnapshot,
  getParent,
} from 'mobx-state-tree';
import { ERROR } from '../constants/constants';
import { translate } from '../i18n/translate';
import { api } from '../services/api/ApiClient';
import { sortByName } from '../utils/sort';
import { CategoryModel, ProductInfoModel, ProductModel } from './models';

const getPriceExpectationFromForecast = (forecast: number | null) => {
  if (forecast === null || !Number.isFinite(forecast)) return null;

  if (forecast < -0.005) return 'DECREASING';
  else if (forecast > 0.005) return 'INCREASING';
  return 'STABLE';
};

const States = [
  'NOT_FETCHED' as const,
  'FETCHING' as const,
  'FETCHED' as const,
  'ERROR' as const,
  'SAVING' as const,
  'SAVED' as const,
];

const ProductStates = ['IDLE' as const, 'FETCHING' as const, 'ERROR' as const];

export const ProductStore = types
  .model({
    state: types.enumeration('State', States),
    products: types.array(ProductModel),
    categories: types.array(CategoryModel),
    currentProductState: types.enumeration('State', ProductStates),
    currentProduct: types.maybe(ProductModel),
    currentProductInfo: types.maybe(types.array(ProductInfoModel)),
  })

  .views(self => ({
    get tabs() {
      return [...getSnapshot(self.categories)]
        .filter(({ id }) => !!this.getCategoryProducts(id).length)
        .map(({ id, slug, name }) => ({
          id,
          slug: `${slug ?? id}`,
          title: name,
        }));
    },

    get sortedProducts() {
      return [...getSnapshot(self.products)].sort(sortByName);
    },

    get product() {
      return self.currentProduct ? getSnapshot(self.currentProduct) : undefined;
    },

    getCategory(categoryId: number) {
      const category = self.categories.find(({ id }) => id === categoryId);
      return category ? getSnapshot(category) : undefined;
    },

    getCategoryProducts(categorySlugOrId?: string | number) {
      const { userStore } = getParent(self);
      const { userArea } = userStore;

      const slug = categorySlugOrId;
      const id = Number(categorySlugOrId);
      const categoryId = id
        ? id
        : self.categories.find(c => c.slug === slug)?.id;

      return [...getSnapshot(self.products)]
        .filter(product => {
          const categoryMatch = product.categoryId === categoryId;
          const areaMatch = userArea === product.area?.code;
          return categoryMatch && areaMatch;
        })
        .sort(sortByName);
    },
  }))
  .actions(self => {
    const getProducts = flow(function* (params: Api.Req.GetProducts = {}) {
      const { notificationStore } = getParent(self);
      self.state = 'FETCHING';

      const response: Api.Response<Api.Res.GetProducts> = yield api.getProducts(
        params,
      );

      if (response.kind === 'ok') {
        self.products = cast(
          response.data.products.map(product => ({
            ...product,
            priceExpectation: getPriceExpectationFromForecast(product.forecast),
          })),
        );
        self.categories = cast(response.data.categories);
        self.state = 'FETCHED';
      } else {
        notificationStore.setError(translate(ERROR.GENERAL_ERROR));
        self.state = 'ERROR';
      }
    });

    const getProduct = flow(function* (params: Api.Req.GetProduct) {
      const { notificationStore } = getParent(self);
      self.currentProductState = 'FETCHING';

      const response: Api.Response<Api.Res.GetProduct> = yield api.getProduct(
        params,
      );

      if (response.kind === 'ok') {
        self.currentProduct = cast({
          ...response.data,
          priceExpectation: getPriceExpectationFromForecast(
            response.data.forecast,
          ),
        });
        self.currentProductState = 'IDLE';
      } else {
        notificationStore.setError(translate(ERROR.GENERAL_ERROR));
        self.currentProductState = 'ERROR';
      }
    });

    return {
      getProducts,
      getProduct,
    };
  });

export interface IProductStore extends Instance<typeof ProductStore> {}

export default ProductStore;
