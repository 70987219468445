import React from 'react';
import ReactGaugeChart from 'react-gauge-chart';
import { THEME } from '../../styles';
import { normalize, round } from '../../utils/math';

export type Scale = [number, number];

interface Props {
  value: number;
  valueScale?: Scale;
  zoom?: number;
}

/**
 * "Zooms" value
 *
 * E.g. if zoom value is 10 and value is 0.1 (10%):
 * Zoomed value will be 0.1 * 10 = 1.
 * When scale is [0, 1], this will mean max value (1):
 * -> needle will point to right for all values over 0.1 (10%).
 */
const zoomValue = (
  value: number,
  valueScale: Scale = [0, 1],
  zoom: number = 1,
) => {
  const [min, max] = valueScale;

  const normalizedValue = normalize(value, min, max, '-1to1');
  const _zoomedValue = Math.abs(normalizedValue) * zoom;
  const zoomedValue = normalizedValue < 0 ? _zoomedValue * -1 : _zoomedValue;

  if (zoomedValue > 1) return 1;
  if (zoomedValue < -1) return 0;
  return normalize(zoomedValue, -1, 1, '0to1');
};

const GaugeChart: React.FC<Props> = ({ value, valueScale, zoom = 1 }) => {
  const zoomedValue = zoomValue(value, valueScale, zoom);

  const formatTextValue = () => {
    const percentValue = value * 100;
    return `${round(percentValue, 10)} %`;
  };

  return (
    <ReactGaugeChart
      id="gauge-chart1"
      nrOfLevels={20}
      percent={zoomedValue}
      colors={['#00FF00', '#FF0000']}
      needleColor={THEME.colors.primary}
      needleBaseColor={THEME.colors.primary}
      textColor={THEME.colors.secondary}
      formatTextValue={formatTextValue}
    />
  );
};

export default GaugeChart;
