import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { AreaModel } from './AreaModel';

const NavSubPageModel = types.model({
  id: types.number,
  slug: types.string,
  title: types.string,
  area: types.maybeNull(AreaModel),
});

export interface INavSubPage extends Instance<typeof NavSubPageModel> {}
export interface NavSubPage extends SnapshotOut<typeof NavSubPageModel> {}
export interface NavSubPageIn extends SnapshotIn<typeof NavSubPageModel> {}

export const NavItemModel = types.model({
  id: types.number,
  name: types.string,
  slug: types.string,
  pages: types.array(NavSubPageModel),
});

export interface INavItem extends Instance<typeof NavItemModel> {}
export interface NavItem extends SnapshotOut<typeof NavItemModel> {}
export interface NavItemIn extends SnapshotIn<typeof NavItemModel> {}
