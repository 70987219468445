import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';
import IconButton from '@material-ui/core/IconButton';
import { useStores } from '../../stores';
import { observer } from 'mobx-react-lite';
import STORAGE from '../../utils/storage';
import { MenuContainer, StyledBadge } from './StyledMenu';
import Tooltip from '../Tooltip';

const LanguageMenu: React.FC = observer(() => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const {
    userStore: {
      availableLanguages,
      updateMe,
      getLanguages,
      userLanguage,
      isLoggedIn,
    },
  } = useStores();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = (languageId: number) => {
    setAnchorEl(null);

    const languageCode = availableLanguages?.find(
      ({ id }) => id === languageId,
    )?.code;

    if (languageCode) setLanguage(languageCode);

    if (isLoggedIn) {
      // Update selected language in backend
      updateMe({ languageId });
    } else if (languageCode) {
      i18n.changeLanguage(languageCode);
      STORAGE.write({
        key: 'LANGUAGE',
        value: { id: languageId, code: languageCode },
      });
    }
  };

  /**
   * Change internal language value if user's language value has changed
   */
  useEffect(() => {
    const languageCode = userLanguage.languageCode;
    if (languageCode && languageCode !== language) {
      setLanguage(languageCode);
    }
  }, [i18n, language, userLanguage.languageCode]);

  /**
   * Fetch languages
   */
  useEffect(() => {
    if (!availableLanguages) {
      getLanguages();
    }
  }, [availableLanguages, getLanguages]);

  if (!availableLanguages || availableLanguages.length <= 1) return null;

  return (
    <MenuContainer>
      {t('common.language.title')}
      <Tooltip title={t(`common.language.tooltip.${language}`) ?? ''}>
        <div>
          <IconButton
            aria-label="language-menu"
            onClick={handleOpenMenu}
            aria-controls="language-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            size="small"
          >
            <StyledBadge
              badgeContent={language.toUpperCase()}
              color="secondary"
            >
              <LanguageIcon fontSize="large" style={{ color: 'white' }} />
            </StyledBadge>
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {availableLanguages?.map(language => (
          <MenuItem
            onClick={() => handleChange(language.id)}
            key={language.id}
            value={language.code}
          >
            {language.name ?? t(`common.language.${language.code}`)}
          </MenuItem>
        ))}
      </Menu>
    </MenuContainer>
  );
});

export default LanguageMenu;
