import React, { useEffect, useRef } from 'react';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { rootStore, RootStore } from './stores/RootStore';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import i18n, { initLang } from './i18n';
import { ROUTES } from './constants/routes';
import { RootStoreProvider, useStores } from './stores';
import { THEME } from './styles';
import { MUI_THEME } from './styles/muiTheme';
import { IRoute } from './types';
import '@fontsource/roboto';
import STORAGE from './utils/storage';
import { triggerPageHitEvent } from './utils/events';
import { useQueryParams } from './utils/hooks';

initLang(STORAGE.read({ key: 'LANGUAGE' })?.code ?? 'en');

const Routes = observer(() => {
  const history = useHistory();

  const {
    areaStore: { areaOfCurrentDomain },
    userStore: { isLoggedIn, userLanguage, setToken, setArea },
    navigationStore: { getNavigation },
    productStore: { getProducts, state: productsState },
  } = useStores() as RootStore;

  // If user changes area, domain is changed and below settings are passed as an query parameters
  const {
    AUTH_TOKEN,
    AREA = areaOfCurrentDomain?.code,
    LANG_ID,
    LANG_CODE,
  } = useQueryParams('AUTH_TOKEN', 'AREA', 'LANG_ID', 'LANG_CODE');

  if (AUTH_TOKEN) setToken(AUTH_TOKEN);
  if (AREA) setArea(AREA);
  if (LANG_ID && LANG_CODE) {
    STORAGE.write({ key: 'LANGUAGE', value: { id: LANG_ID, code: LANG_CODE } });
  }

  const defaultView = !isLoggedIn ? '/login' : '/products';

  // All routes available for authenticated users, and only nonAuth routes for others
  const isAllowedRoute = ({ nonAuth }: IRoute) => isLoggedIn || nonAuth;

  // Set site language based on user's language
  const languageRef = useRef<string>();

  // Check if language has changed
  useEffect(() => {
    const { languageCode: code, languageId: id } = userLanguage;
    if (code?.length && code !== languageRef.current) {
      const initializingLanguage = languageRef.current === undefined;
      languageRef.current = code;

      i18n.changeLanguage(code);
      STORAGE.write({ key: 'LANGUAGE', value: { id, code } });

      // Fetch new localized data:
      getNavigation();
      if (productsState === 'FETCHED') getProducts();

      if (!initializingLanguage) history.push('/');
    }
  }, [getNavigation, getProducts, history, productsState, userLanguage]);

  // Send page hit on mount
  useEffect(() => {
    triggerPageHitEvent(history.location.pathname + history.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Send page hits on history change
  useEffect(() => {
    const unlisten = history.listen(() => {
      // This will be evaluated on every route change (but it's not triggered on mount!)
      triggerPageHitEvent(history.location.pathname + history.location.search);
    });
    // This function will be invoked on component unmount and will clean up the event listener.
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Switch>
      {ROUTES.filter(isAllowedRoute).map((route: IRoute) => {
        const Screen = route.component;
        return (
          <Route exact key={route.href} path={route.href}>
            <Screen />
          </Route>
        );
      })}
      <Redirect to={defaultView} />
    </Switch>
  );
});

const App = () => {
  return (
    <div className="App">
      <RootStoreProvider value={rootStore}>
        <Router>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={MUI_THEME}>
              <ThemeProvider theme={THEME}>
                <Routes />
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </Router>
      </RootStoreProvider>
    </div>
  );
};

export default App;
