import { Instance, types, SnapshotOut, SnapshotIn } from 'mobx-state-tree';
import { CompanyModel } from './CompanyModel';

export const UserModel = types.model({
  id: types.maybe(types.number),
  username: types.string,
  name: types.string,
  company: types.maybeNull(CompanyModel),
  languageId: types.maybeNull(types.number),
});

export interface IUserModel extends Instance<typeof UserModel> {}
export interface User extends SnapshotOut<typeof UserModel> {}
export interface UserIn extends SnapshotIn<typeof UserModel> {}
