import { Typography } from '@material-ui/core';
import { FormApi, SubmissionErrors } from 'final-form';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Input, SubmitButton } from '../../components/FormControls';
import Toast, { ToastMessage } from '../../components/Toast';
import { api } from '../../services/api';

const StyledForm = styled.form`
  max-width: 500px;
  margin: auto;
`;

type SubmitState = 'IDLE' | 'PROCESSING' | 'SUCCESS' | 'ERROR';

const ChangePasswordForm: React.FC = () => {
  const { t } = useTranslation();

  const [submitState, setSubmitState] = useState<SubmitState>('IDLE');
  const [message, setMessage] = useState<ToastMessage>();

  const handleSubmit = async (
    values: Api.Dto.ChangePassword,
    _form: FormApi<any, any>,
    _callback?: (errors?: SubmissionErrors) => void,
  ) => {
    setSubmitState('PROCESSING');
    const response = await api.changePassword(values);
    if (response.kind === 'ok' && response.data.success) {
      setSubmitState('SUCCESS');
      setMessage({
        severity: 'success',
        text: t('userSettings.changePassword.submit_success'),
      });
    } else {
      setSubmitState('ERROR');
      setMessage({
        severity: 'error',
        text: t('userSettings.changePassword.submit_error'),
      });
    }
  };

  const FORM_FIELDS = [
    {
      label: t('userSettings.changePassword.oldPassword'),
      type: 'password',
      id: 'oldPassword',
    },
    {
      label: t('userSettings.changePassword.newPassword'),
      type: 'password',
      id: 'newPassword',
    },
    {
      label: t('userSettings.changePassword.newPasswordConfirm'),
      type: 'password',
      id: 'newPasswordConfirm',
    },
  ];

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        subscription={{
          pristine: true,
          hasValidationErrors: true,
        }}
        render={({ handleSubmit, pristine, hasValidationErrors, form }) => (
          <StyledForm
            onSubmit={async (e: any) => {
              e.preventDefault();
              await handleSubmit();
              form.reset();
            }}
          >
            <Typography variant="h2" align="center">
              {t('userSettings.changePassword.title')}
            </Typography>

            {FORM_FIELDS.map(({ label, type, id }) => (
              <Input
                key={id}
                label={label}
                type={type}
                id={id}
                name={id}
                size="small"
                margin="normal"
              />
            ))}

            <SubmitButton
              label="userSettings.changePassword.submit"
              loading={submitState === 'PROCESSING'}
              disabled={pristine || hasValidationErrors}
              style={{ width: '100%' }}
            />
          </StyledForm>
        )}
      />
      <Toast message={message} messageSetter={setMessage} />
    </>
  );
};

export default ChangePasswordForm;
