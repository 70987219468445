import React, { ChangeEvent, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SubmitButton } from './FormControls';
import { isSlider, isTextarea } from '../utils/questionnaire';
import PersonForm from './PersonForm';
import { Person } from '../stores/models';
import { Feedback, FeedbackQuestion } from '../types/Feedback';
import Slider from './Slider';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em auto 2em auto;
  width: 75%;

  textarea {
    width: 100%;
    margin-bottom: 2em;
    padding: 1rem;
    border-radius: 8px 8px 4px 8px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    resize: vertical;
  }

  @media ${p => p.theme.breakpoints.tablet} {
    width: 90%;
  }
`;

const QuestionContainer = styled.div`
  width: 100%;
  margin: 2em 0;
  text-align: center;

  .questions {
    &__slider-labels {
      &--container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: -5px;
      }
    }
  }

  textarea {
    margin-top: 0.5em;
  }
`;

interface Props {
  title?: string;
  feedback: Feedback;
  setFeedback: React.Dispatch<React.SetStateAction<Feedback | undefined>>;
  onSend: () => void;
  isSent: boolean;
}

const FeedbackBox: React.FC<Props> = ({
  title,
  feedback,
  setFeedback,
  onSend,
  isSent,
}) => {
  const { t } = useTranslation();

  const getInitialSliderValue = (scale: [number, number]) =>
    Math.floor((scale[0] + scale[1]) / scale.length);

  /*
   * Set default answer to all questions on mount
   */
  useEffect(() => {
    setFeedback({
      ...feedback,
      questions: feedback.questions.map(question => {
        const { scaleMin, scaleMax } = question;
        return {
          ...question,
          answer:
            isSlider(question) && scaleMin && scaleMax
              ? question.answer ?? getInitialSliderValue([scaleMin, scaleMax])
              : undefined,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSliderChange =
    (questionId: number, min: number, max: number) =>
    (value: number | null) => {
      setFeedback({
        ...feedback,
        questions: feedback.questions.map(question => {
          const answer = Number(value) || getInitialSliderValue([min, max]);
          return {
            ...question,
            // Update changed answer for the given question:
            answer: questionId === question.id ? answer : question.answer,
          };
        }),
      });
    };

  const handleTextareaChange =
    (questionId: number) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFeedback({
        ...feedback,
        questions: feedback.questions.map(question => ({
          ...question,
          // Update changed answer for the given question:
          answerText:
            questionId === question.id
              ? e.currentTarget.value
              : question.answerText,
        })),
      });
    };

  const handlePersonChange = (person: Person) => {
    setFeedback({
      ...feedback,
      person,
    });
  };

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isSent) {
      onSend();
    }
  };

  const renderSlider = ({
    id,
    scaleMin,
    scaleMax,
    questionType,
    isInputVisible,
    sliderStep,
    sliderInputDecoration,
    scaleMaxLabel,
    scaleMinLabel,
  }: FeedbackQuestion) => {
    if (scaleMin === undefined || scaleMax === undefined) return null;
    if (scaleMin >= scaleMax) return null;

    return (
      <>
        <Slider
          min={scaleMin}
          max={scaleMax}
          defaultValue={getInitialSliderValue([scaleMin, scaleMax])}
          step={sliderStep ?? 1}
          onChange={handleSliderChange(id, scaleMin, scaleMax)}
          disabled={isSent}
          marks
          questionType={questionType}
          isInputVisible={isInputVisible}
          sliderInputDecoration={sliderInputDecoration}
          scaleMaxLabel={scaleMaxLabel}
          scaleMinLabel={scaleMinLabel}
        />
      </>
    );
  };

  const renderTextarea = ({ id }: FeedbackQuestion) => (
    <textarea onChange={handleTextareaChange(id)} rows={5} disabled={isSent} />
  );

  const personForm = (
    <PersonForm
      formTitle={t('feedback.person_form_title')}
      person={feedback.person}
      onPersonChange={handlePersonChange}
    />
  );

  return (
    <Form onSubmit={handleSubmit}>
      {!!title?.length && <Typography variant="h2">{title}</Typography>}

      {feedback.personFormPosition === 'top' && personForm}

      {feedback.questions.map(questionBlock => {
        const { id, question } = questionBlock;

        return (
          <QuestionContainer key={id}>
            <Typography variant="body1">{question}</Typography>
            {isSlider(questionBlock) && renderSlider(questionBlock)}
            {isTextarea(questionBlock) && renderTextarea(questionBlock)}
          </QuestionContainer>
        );
      })}

      {feedback.personFormPosition === 'bottom' && personForm}

      <div>
        <SubmitButton
          loading={false}
          label={t('feedback.send')}
          disabled={isSent}
        />
      </div>
    </Form>
  );
};

export default FeedbackBox;
